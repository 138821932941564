<template>
      <div class="row">
        <div class="col-12">
          <div class="current-srch-container">
          <h4 class="compact-srch-title">Tu búsqueda:</h4>
            <div class="current-srch-data">
              <div class="current-srch-ond">
                <h1>{{querySearch.DepartureStation}}<img src="@/img/plane-32.png" alt="Flying to">{{querySearch.ArrivalStation}}</h1>
              </div>

              <div class="current-srch-date">
                <div class="current-srch-icon-box"></div>
                <div class="current-srch-date-box">
                  <div class="current-srch-day">
                    <p>{{getDay(querySearch.DepartureDate)}}</p>
                    <p>{{getMonth(querySearch.DepartureDate)}}</p>
                  </div>
                    <div class="current-srch-year">
                      <p>{{getYear(querySearch.DepartureDate)}}</p>
                    </div>
                </div>
              </div>

              <div class="current-srch-date" v-if="querySearch.ReturnDate">
                <div class="current-srch-icon-box"></div>
                <div class="current-srch-date-box">
                  <div class="current-srch-day">
                    <p>{{getDay(querySearch.ReturnDate)}}</p>
                    <p>{{getMonth(querySearch.ReturnDate)}}</p>
                  </div>
                    <div class="current-srch-year">
                      <p>{{getYear(querySearch.ReturnDate)}}</p>
                    </div>
                </div>
              </div>

              <div class="current-srch-pax">
                <div class="current-stch-pax-adt"><span>{{querySearch.ADT}}</span></div>
                <div class="current-stch-pax-chd"><span>{{querySearch.CHD}}</span></div>
                <div class="current-stch-pax-inf"><span>{{querySearch.INF}}</span></div>
              </div>
              <!--div class="current-srch-change">
                <button type="button" class="btn">Cambiar</button>
              </div-->
            </div>
          </div>
        </div>
      </div>

</template>

<script>
  import { mapState } from 'vuex';
  import moment from 'moment';
  export default {
    name: 'search-detail',
    props:[ "title", "flights", "isDeparture"],
    methods: {
    },
    computed: {
      ...mapState(['departureFare', 'returnFare', 'querySearch'])
    },
    methods: {
        getDay: function(d) {
            return moment(d).locale('es').format('DD');
        },
        getMonth: function(d) {
            return moment(d).locale('es').format('MMM');
        },
        getYear: function(d) {
            return moment(d).locale('es').format('YYYY');
        },
    }

  }
</script>