<template>

  <div class="itin-flight" v-if="booking.BalanceDue > 0">
    <div class="itin-subheader">
      <div class="col-12">
        <div class="current-srch-container">
          <div class="row d-flex align-items-center text-left">
            <div class="col-12 col-sm-9 text-center text-sm-left px-0">
              <h4>
                <font-awesome-icon icon="credit-card"/>
                Saldo Pendiente: ARS {{toCurrencyFormat(booking.BalanceDue)}}
              </h4>
            </div>
            <div class="col-12 col-sm-3 text-right px-0">
              <button class="btn btn-andes w-100" @click="goToPayment">Pagar</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--      <div class="itin-subheader">-->
    <!--        <h4 class="pull-left"><font-awesome-icon icon="credit-card"/> Saldo Pendiente: ARS {{toCurrencyFormat(booking.BalanceDue)}}</h4>-->
    <!--        <a class="card-btn" @click="goToPayment" >Pagar</a>-->
    <!--      </div>-->
  </div>

</template>

<script>
  import {mapState} from 'vuex';
  import moment from 'moment';
  import {formatMoney} from "../../utils/utils";

  export default {
    name: 'payment-pending',
    computed: {
      ...mapState(['booking']),
    },
    methods: {
      toCurrencyFormat: function (amount) {
        return formatMoney(amount);
      },
      goToPayment: function () {
        /*gtag('event', 'pay_pending_click', {
          'event_category': "payment"
        });*/
        this.$router.replace('/payment-pending');
      }
    }
  }
</script>

