<template>
  <div class="itin-flight">
    <div class="itin-subheader">
        <div class="col-12">
          <div class="current-srch-container">
            <div class="row d-flex align-items-center text-center text-sm-left">
              <div class="col-12 col-sm-9 text-left px-0">
                <h4><font-awesome-icon :icon="icon"/> {{title}}</h4>
              </div>
              <div class="col-12 col-sm-3 text-right px-0">
                <button class="btn btn-andes w-100 mb-1" @click="checkin">Web Check-in</button>
                <button class="btn btn-andes w-100" @click="openBoarding" v-if="paxChecked" >Descargar Tarjeta</button>
              </div>
            </div>
          </div>
        </div>
    </div>


    <div class="row text-center dot-border mx-0 my-2" v-for="leg in flight.Legs">
      <div class="col-12 col-sm-3">
        <h4>Vuelo {{leg.InventoryLeg.FlightNumber}}</h4>
        <h5>CLASE {{flight.ClassOfService}}</h5>
      </div>
      <div class="col-12 offset-sm-2 col-sm-2">
        <h4>{{STD(leg)}}hs {{leg.InventoryLeg.DepartureStation}}</h4>
      </div>
      <div class="col-12 col-sm-2">
        <img src="../../img/plane-32.png" alt=""/>
      </div>
      <div class="col-12 col-sm-2">
        <h4>{{STA(leg)}}hs {{leg.InventoryLeg.ArrivalStation}}</h4>
      </div>
    </div>

    <div class="itin-flight-details-2">
      <div class="itin-flight-details-duration">
        <p>
          <font-awesome-icon icon="clock"/>
          Duración: <strong>{{duration}}hs</strong>
        </p>
      </div>
      <div class="itin-flight-details-seats">
        <div>
          <h6>Asientos</h6>
        </div>
        <div>

          <div class="itin-flight-details-seats" v-for="(pax, index) in paxs">
            <div class="itin-flight-details-seat-index">
              <h5>{{index + 1}}</h5>
            </div>
            <div class="itin-flight-details-seat-designator">
              <h5>{{pax.paxLeg.UnitDesignator}}</h5>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>

</template>

<script>
  import {mapState} from 'vuex';
  import moment from 'moment';
  import { normalizeText  } from '@/utils/tools';

  export default {
    name: 'flight-summary',
    props: ['flightType', 'flight'],
    computed: {
      ...mapState(['booking', 'departureSelected', 'returnSelected', 'departureFare', 'returnFare', 'lastName', 'dni']),
      title: function () {
        if (this.flightType == 0) {
          return "SALIDA - " + moment(this.flight.Legs[0].InventoryLeg.STD).locale('es').format('DD MMMM YYYY');
        } else {
          return "REGRESO - " + moment(this.flight.Legs[0].InventoryLeg.STD).locale('es').format('DD MMMM YYYY');
        }
      },
      duration: function () {
        var departure = moment(this.flight.Legs[0].InventoryLeg.STD);
        var arrival = moment(this.flight.Legs[this.flight.Legs.length - 1].InventoryLeg.STA);
        return moment.utc(arrival.diff(departure)).format("HH:mm");
      },
      icon: function () {
        return "plane-departure";
      },
      // STD: function () {
      //   return moment(this.flight.Legs[0].InventoryLeg.STD).format('HH:mm');
      // },
      // STA: function () {
      //   return moment(this.flight.Legs[this.flight.Legs.length - 1].InventoryLeg.STA).format('HH:mm');
      // },
      checkinPending: function () {
        if ((this.booking.BalanceDue && parseFloat(this.booking.BalanceDue)>0) || !this.flight.Legs[0].InventoryLeg.WebCheckinOpen) {
          return false;
        }
        for (let pax of this.booking.Passengers) {
          let segment = _.find(pax.Segments, (c) => c.DepartureStation == this.flight.DepartureStation &&
            c.ArrivalStation == this.flight.ArrivalStation && c.DepartureDate == this.flight.DepartureDate);
          if (segment && segment.Legs[0].LiftStatus == 0) {
          return true;

          }
        }
        return false;
      },
      paxChecked: function () {
        if (this.booking.BalanceDue && parseFloat(this.booking.BalanceDue)>0) {
          return false;
        }
        for (let pax of this.booking.Passengers) {
          let segment = _.find(pax.Segments, (c) => c.DepartureStation == this.flight.DepartureStation &&
            c.ArrivalStation == this.flight.ArrivalStation && c.DepartureDate == this.flight.DepartureDate);
          if (segment && segment.Legs[0].LiftStatus == 1) return true;
        }
        return false;
      },
      hasAdults: function() {
        for (var passenger of this.booking.Passengers) {
          if (passenger.PaxType == 'ADT' || passenger.PaxType == 'NREV' || passenger.PaxType == 'CBUS') {
            return true;
          }
        }
        return false;
      },
      paxs: function () {
        var list = [];
        for (var pax of this.booking.Passengers) {
          if (this.lastName && this.tlc(normalizeText(this.lastName)) != this.tlc(normalizeText(pax.LastName))) continue;
          if (this.dni && this.tlc(this.dni) != this.tlc(pax.DocNumber)) continue;
          for (var seg of pax.Segments) {
            if (seg.SegmentFlightKey == this.flight.SegmentFlightKey) {
              list.push({'pax': pax, 'paxLeg': seg.Legs[0]});
            }
          }
        }
        this.currentPax = list[0]

        return list
      }
    },
    methods: {
      tlc (t) {
          return t.replace(/ /g, '').toLowerCase();
      },
      STD: function (leg) {
        return moment(leg.InventoryLeg.STD).format('HH:mm');
      },
      STA: function (leg) {
        return moment(leg.InventoryLeg.STA).format('HH:mm');
      },
      checkin: function () {
        this.$store.commit('checkinFlight', this.flight);
        this.$router.replace("checkin");
      },
      openBoarding: function () {
        this.$store.commit('boardingFlight', this.flight);
        this.$router.replace("boarding");
      }
    }
  }
</script>

