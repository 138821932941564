<template>
  <div class="card shadow">
    <div class="card-header">Mi Reserva</div>
    <div class="card-body">
      <form ref="form">

        <div class="form-row align-items-end px-4 mb-3">
             <div class="col-md-3 col-xs-12">
                  <input type="radio" id="flightDate" value="flightDate" v-model="type">
                  <label for="flightDate" class="ml-3">Con Documento</label>
            </div>
             <div class="col-md-3 col-xs-12">
                  <input type="radio" id="recordLocator" value="recordLocator" v-model="type">
                  <label for="recordLocator" class="ml-3">Con Código de Reserva</label>
            </div>
        </div>
        <div class="form-row align-items-end">
          <div class="form-group col-md-3 col-sm-12">
            <label>Apellido</label>
            <input type="text" v-model="lastName" :class="errors.lastName?'form-control form-error': 'form-control'"/>
          </div>
          <div class="form-group col-md-3  col-sm-12" v-if="type=='flightDate'">
            <label>Documento</label>
            <input type="text" v-model="dni" :class="errors.dni?'form-control form-error': 'form-control'"/>
          </div>
          <div class="form-group col-md-3  col-sm-12" v-if="type=='flightDate'">
            <label>Fecha de Vuelo</label>
            <input type="date" v-model="flightDate" :class="errors.flightDate?'form-control form-error': 'form-control'"/>
          </div>
          <div class="form-group col-md-3 col-sm-12"  v-if="type=='recordLocator'">
            <label>Código de Reserva</label>
            <input type="text" v-model="recordLocator" :class="errors.recordLocator?'form-control form-error': 'form-control'"/>
          </div>
          <div class="form-group col-md-3 col-sm-12">
            <button type="button" class="btn btn-andes w-100" @click="search">BUSCAR</button>
          </div>
        </div>
        <!--div class="form-row align-items-end">
            Impuestos, tasas y cargos $14.138. Válido mes de Marzo
        </div-->
        <div class="form-group" v-show="errors.message">
          <p class="is-error">{{errors.message}}</p>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
  import { getBooking } from '@/api/api';
  import { mapState } from 'vuex';
  export default {
    name: 'booking-date-search',
    computed: {
      ...mapState(['bookingSearch']),
    },
    data: function () {
      return {
        type: 'flightDate',
        lastName: '',
        dni: '',
        recordLocator: '',
        flightDate: null,
        errors: {
          lastName: false,
          dni: false,
          recordLocator: false,
          flightDate: false,
          message: ""
        }
      }
    },
    methods: {
      validate: function() {
        this.errors.message = ""
        this.errors.lastName = this.lastName.length == 0;
        if (this.type == 'recordLocator') this.errors.recordLocator = this.recordLocator.length == 0;
        if (this.type == 'flightDate') this.errors.flightDate = !this.flightDate;
        if (this.type == 'flightDate') this.errors.dni = !this.dni || this.dni.length == 0;
        var isValid = true;
        if (this.errors.lastName) isValid = false;
        if (this.errors.recordLocator && this.type == 'recordLocator') isValid = false;
        if (this.errors.flightDate && this.type == 'flightDate') isValid = false;
        if (this.errors.dni && this.type == 'flightDate') isValid = false;

        if (!isValid) {
          this.errors.message = "Complete ambos campos por favor."
        }
        return isValid;
      },

      search: function () {
        if (this.validate()) {
          var queryParams = {
            rl: this.recordLocator,
            ln: this.lastName,
            fd: this.flightDate,
            dni: this.dni,
          }
          this.$store.commit('loading', true);
          getBooking(queryParams, (result) => {
            if (result.error) {
              this.errors.lastName = true;
              this.errors.recordLocator = true;
              this.errors.flightDate = true;
              this.errors.dni = true;
              this.errors.message = result.error;
            } else {
              if (this.type == 'flightDate') {
                  this.$store.dispatch('paxLastName', {lastName: this.lastName, dni: this.dni});
              } else {
                  this.$store.dispatch('paxLastName', null);
              }
              this.$store.dispatch('updateBooking', result);
              this.$router.push('itinerary');
            }
            this.$store.commit('loading', false);
          });
        }
      }
    },
    mounted() {
      if (!_.isEmpty(this.bookingSearch)) {
        this.errors.lastName = true;
        this.errors.recordLocator = true;
        this.errors.flightDate = true;
        this.errors.dni = true;
        this.errors.message = this.bookingSearch.error;
        this.recordLocator = this.bookingSearch.recordLocator;
        this.lastName = this.bookingSearch.lastName;
        this.flightDate = this.bookingSearch.flightDate;
        this.dni = this.bookingSearch.dni;
        this.$store.dispatch("bookingSearch", {});
      }
    }
  }
</script>

