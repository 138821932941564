<template>
  <div class="container payment-page">
    <form ref="form" id="pay">
      <transition name="fade">
        <div class="form-row" v-if="this.errors.noPayment" transition="fade">
          <div class="form-group col">
            <div class="alert alert-danger" role="alert">
              {{this.errors.noPayment}}
            </div>
          </div>
        </div>
      </transition>
      <!-- card number-->
      <div class="form-row">
        <div class="form-group col-md-8">
          <label>Número de tarjeta</label>
          <input type="text" id="cardNumber" data-checkout="cardNumber" v-model="cardNumber" :class="errors.cardNumber?'form-control form-error': 'form-control'"
            v-on:keyup="onCardNumberChange($event.target.value)"/>
        </div>
        <div class="form-group col-md-2">
          <label>CVC</label>
          <input type="number" id="securityCode" data-checkout="securityCode" v-model="securityCode" :class="errors.securityCode?'form-control form-error': 'form-control'"/>
        </div>
        <div class="form-group col-md-2">
          <label>Pago</label>
          <transition name="fade">
            <img class="form-control w-auto" v-show="paymentMethod" :src="paymentThumbnail"/>
          </transition>
        </div>
      </div>

      <div class="form-row">
        <div class="form-group col">
          <label>Nombre en la Tarjeta</label>
          <input type="text" id="cardholderName" data-checkout="cardholderName" v-model="cardholderName" :class="errors.cardholderName?'form-control form-error': 'form-control'"/>
        </div>
      </div>

      <div class="form-row">
        <div class="form-group col-md-3">
          <label>Vencimiento</label>
          <div class="row">
            <div class="pb-3 col-md-6 pr-md-2 pb-md-0">
              <!--<input type="number" id="cardExpirationMonth" data-checkout="cardExpirationMonth" v-model="cardExpirationMonth" :class="errors.cardExpirationMonth?'form-control form-error': 'form-control'"/>-->

              <select class="form-control" id="cardExpirationMonth" v-model="cardExpirationMonth"
                :class="errors.cardExpirationMonth?'form-control form-error': 'form-control'" data-checkout="cardExpirationMonth"
                >
                <option value="" type="text">Mes</option>
                <option :value="i" v-for="i in months" :key="`months-${i}`" type="text">{{i}}</option>
              </select>

            </div>
            <div class="col-md-6 pl-md-0">
              <!--<input type="number" id="cardExpirationYear" data-checkout="cardExpirationYear" placeholder="0000" v-model="cardExpirationYear" :class="errors.cardExpirationYear?'form-control form-error': 'form-control'"/>-->
              <select class="form-control" id="cardExpirationYear" v-model="cardExpirationYear"
                :class="errors.cardExpirationYear?'form-control form-error': 'form-control'" data-checkout="cardExpirationYear" >
                <option value="" type="text">Año</option>
                <option :value="i" v-for="i in years" :key="`months-${i}`" type="text">{{i}}</option>
              </select>
            </div>
          </div>
        </div>
        <div class="form-group col-md-4">
          <label>Tipo de Documento</label>
          <select class="form-control" id="docType" v-model="docType" data-checkout="docType"
          :class="errors.docType?'form-control form-error': 'form-control'">
            <option :value="type.id" v-for="type in docTypes" :key="type.id" :type="type.type">{{type.name}}</option>
          </select>
        </div>
        <div class="form-group col-md-5">
          <label>Número</label>
          <input type="text" class="form-control" id="docNumber" v-model="docNumber" data-checkout="docNumber"
          :class="errors.docNumber?'form-control form-error': 'form-control'"/>
        </div>
      </div>

      <div class="form-row">
        <div class="form-group col">
          <label>Dirección de e-mail</label>
          <input type="email" id="email" v-model="email" :class="errors.email?'form-control form-error': 'form-control'"
            />
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col">
          <label>Formas de pago</label>
          <select class="form-control" id="installments" v-model="installmentSelected"
            :class="errors.installmentSelected?'form-control form-error': 'form-control'">
            <option :value="i" v-for="i in installments.payer_costs" :key="`installment-${i.installments}`">{{i.recommended_message}}</option>
          </select>
        </div>
      </div>

    </form>
  </div>
</template>

<script>
  import { makePayment, getBooking } from '@/api/api';
  import { mapState } from 'vuex';
  import moment from 'moment';

  export default {
    components: {},
    name: 'mp-form',
    data: function () {
      return {
        cardToken:"",
        docTypes:[],
        paymentMethods: [],
        paymentMethod: null,
        installments: [],
        installmentSelected: null,
        cardNumber: "",
        securityCode: "",
        cardholderName:"",
        cardExpirationMonth:"",
        cardExpirationYear:"",
        docType:"",
        docNumber:"",
        email: "",
        errors: {
          docType: "",
          installmentSelected: "",
          cardNumber: "",
          securityCode: "",
          cardholderName:"",
          cardExpirationMonth:"",
          cardExpirationYear:"",
          noPayment: "",
          email:""
        }
      }
    },
    computed: {
      ...mapState(["booking"]),
      paymentThumbnail: function () {
        if (this.installments && this.installments.length) {
          return this.installments[0].issuer.thumbnail;
        }
        if (this.paymentMethod) {
          return this.paymentMethod.thumbnail;
        }
        return "";
      },
      months: function () {
        var list = [];
        for (var i=1; i<13; i++) {
          list.push(i);
        }
        return list;
      },
      years: function () {

        var list = [];
        var currentYear = moment().year();
        for (var i=currentYear; i <= currentYear + 15; i++) {
          list.push(i);
        }

        return list;
      }
    },
    methods: {
      isValid: function() {
        this.errors.cardNumber = !this.paymentMethod || this.cardNumber.length != this.paymentMethod.settings[0].card_number.length;
        this.errors.securityCode = !this.paymentMethod || this.securityCode.length != this.paymentMethod.settings[0].security_code.length;
        this.errors.cardholderName = this.cardholderName.length == 0;
        this.errors.cardExpirationMonth = this.cardExpirationMonth == '';
        this.errors.cardExpirationYear = this.cardExpirationYear == '';
        this.errors.docType = this.docType.length == 0;
        // this.errors.docNumber = this.docNumber.length == 0;

        if (this.docType.length > 0) {
          //console.log(this.docTypes, this.docType)
          let docType = _.find(this.docTypes, (doc) => doc.id == this.docType );
          this.errors.docNumber = this.docNumber.length < docType.min_length || this.docNumber.length > docType.max_length;
        } else {
          this.errors.docNumber = true;
        }

        this.errors.installmentSelected = this.installmentSelected == null;

        var emailPattern = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        this.errors.email = !emailPattern.test(this.email);

        let expiration = moment().set({month:this.cardExpirationMonth -1, year:this.cardExpirationYear,hour:23,minute:59,second:59})
        console.log("expiration ", expiration);
        if (moment().isAfter(expiration)) {
          this.errors.cardExpirationMonth = true;
          this.errors.cardExpirationYear = true;
        }

        var isValid = true;
        isValid = isValid && !this.errors.cardNumber;
        isValid = isValid && !this.errors.securityCode;
        isValid = isValid && !this.errors.cardholderName;
        isValid = isValid && !this.errors.cardExpirationMonth;
        isValid = isValid && !this.errors.cardExpirationYear;
        isValid = isValid && !this.errors.docType;
        isValid = isValid && !this.errors.docNumber;
        isValid = isValid && !this.errors.installment;
        isValid = isValid && !this.errors.email;

        return isValid;
      },
      onMakePayment: function() {
        this.errors.noPayment = "";
        if (this.isValid()){
          this.$store.commit('loading', true);
          console.log('createToken');
          Mercadopago.createToken(document.querySelector('#pay'), this.onGetToken);
        }
      },
      onGetToken: function(status, json) {
        console.log("onGetToken", json);
        if (status == 200) {
          this.cardToken = json.id;
          this.callServer();
        } else {
          if (json.error == 'bad_request') {
            this.errors.noPayment = "Algunos datos fueron rechazados por MercadoPago. Revise por favor los datos ingresados.";
            this.$store.commit('loading', false);
          }
        }
      },
      callServer:function () {
        let paymentAmount = parseInt(this.booking.BalanceDue);
        makePayment(
          this.booking.RecordLocator,
          this.booking.BookingID,
          this.cardToken,
          this.installments.payment_method_id,
          this.installments.issuer.id,
          this.installments.processing_mode,
          this.installmentSelected.installments,
          this.email,
          this.installmentSelected.total_amount,
          this.installments.merchant_account_id,
          this.booking.BalanceDue,
          (result) => {
            console.log(result);
            if (result.result == 'approved') {
              this.$store.commit('booking', result.booking);
              this.$router.replace('itinerary');
              
              /*gtag('event', 'pay_approved', {
                'event_category':"payment",
                'value': paymentAmount
              });*/
            } else if (result.result == 'rejected') {
              this.errors.noPayment = "Rechazado: " + " " + result.detail ;
              this.$store.commit('booking', result.booking);
              Mercadopago.clearSession();
              /*gtag('event', 'pay_rejected', {
                'event_category':"payment",
                'event_label': result.detail
              });*/
            } else {
              // error: busco el booking actualizado para verificar si impacto o no el pago.
              let params = {
                rl: this.booking.RecordLocator,
                ln: this.booking.Passengers[0].LastName
              };
              getBooking(params, (result) => {
                this.$store.dispatch('updateBooking', result);
                for (var p of result.Payments) {
                  if (p.AuthorizationStatus != 4 && !_.isEmpty(p.MercadoPagoPayment) && p.MercadoPagoPayment.Status == 'approved') {
                    this.$router.replace('itinerary');
                  }
                }
              });

              this.errors.noPayment = result.message ;
              Mercadopago.clearSession();

              /*gtag('event', 'pay_failed', {
                'event_category':"payment",
                'event_label': result.message
              });*/
            }
            this.$store.commit('loading', false);
          }
        )
      },
      onCardNumberChange: function (cardNumber) {

        this.cardNumber = cardNumber.replace(/\s/g,'');

        if (cardNumber.length < 6) {
          this.paymentMethod = null;
          this.installments == [];
          this.installmentSelected == null;
          this.errors.noPayment = "";
        } else if (cardNumber.length >= 6 || this.installmentSelected == null) {
          Mercadopago.getPaymentMethod({"bin": this.cardNumber.substr(0,6), "processing_mode":"gateway"}, (status, response) => {
            console.log("getPaymentMethod ", status, response);
            if (status == 200) {
              this.paymentMethods = response;
              if (this.paymentMethods!=undefined && this.paymentMethods.length > 0) {
                this.paymentMethod = response[0];
                for (var p of response) {
                  if ('gateway' in p.processing_modes) {
                    this.paymentMethod = p;
                  }
                }

              }
              this.errors.noPayment = "";
            } else {
              this.errors.noPayment = "Medio de pago no disponible para esta tarjeta ó posible mal ingreso de número";
            }
          });

          Mercadopago.getInstallments({
              "bin": this.cardNumber.substr(0,6), "amount": parseFloat(this.booking.BalanceDue), "processing_mode":"gateway"
            }, (status, response) => {
            //console.log("getInstallments ", status, response);
            if (status == 200){
              console.log(response)
              this.installments = response[0];
              this.installmentSelected = this.installments.payer_costs[0];
              for (var i of response) {
                  if (i.processing_mode == 'gateway') {
                    this.installments = i;
                    this.installmentSelected = this.installments.payer_costs[0];
                  }
                }
            } else {
              this.errors.noPayment = "Medio de pago no disponible para esta tarjeta ó posible mal ingreso de número";
            }
          });
        }
      }
    },
    mounted: function () {
      Mercadopago.clearSession();
      Mercadopago.setPublishableKey(process.env.MP_ACCESS_TOKEN);

      if (this.booking && this.booking.ContactInformation && this.booking.ContactInformation.length) {
        this.email = this.booking.ContactInformation[0].EmailAddress;
      }

      Mercadopago.getIdentificationTypes((status, response) => {
        if (status == 200) {
          this.docTypes = response;
        }
      });

    },
    watch: {
        installmentSelected: function() {
            this.$store.commit('installmentSelected', this.installmentSelected);
        }
    }
  }
</script>

