<template>
    <div class="mt-3 seat-pax-info">
        <h5>Asignación de asientos</h5>
        <div class="card shadow itin">
            <div class="itin-flight">
                <div class="itin-subheader">
                    <div class="row">
                        <div class="col text-left">
                            <h5 class="mb-0">Vuelo OY {{checkinFlight.Legs[0].InventoryLeg.FlightNumber}}</h5>
                        </div>
                        <div class="col text-right">
                            <h5 class="mb-0"> <strong>{{checkinFlight.DepartureStation}} <img src="@/img/plane-32.png" alt="Flying to">
                             {{checkinFlight.ArrivalStation}}</strong></h5>
                        </div>
                    </div>
                </div>
            </div>
            <div class="pax-seat-info-table">
                <div class="row padding10">
                    <div class="col-6">
                        <h5><strong>Pasajero</strong></h5>
                    </div>
                    <div class="col-3">
                        <h5><strong>Asiento</strong></h5>
                    </div>
                    <div class="col-3">
                        <h5><strong>Valor</strong></h5>
                    </div>
                </div>

                <div :class="pax.segId == currentPax.segId?'padding10 is-current':'padding10'" v-for="pax in paxs">
                    <div class="row" @click="onSelect(pax)">
                        <div class="col-6">
                            {{pax.pax.LastName}} {{pax.pax.FirstName}}
                        </div>
                        <div class="col-3">
                            {{pax.paxLeg.UnitDesignator}}
                        </div>
                        <div class="col-3">
                            ARS 0
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
  import { mapState } from 'vuex';

  export default {
    name: "SeatSelectPassenger",
    props:["paxs", "currentPax"],
    computed: {
      ...mapState(['booking', 'checkinFlight', 'segmentsToCheck'])
    },
    data: function () {
      return {
      }
    },
    methods: {
      onSelect: function (selectedPax) {
        // console.log(selectedPax);
        this.$emit('select-pax', selectedPax);
      }
    },
    mounted: function() {
      // console.log(this.currentPax);
    }

  }
</script>

<style scoped>

</style>