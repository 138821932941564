<template>
    <div class="itin-flight">
<!--        <div class="itin-subheader">-->
<!--            <h4 class="pull-left"><font-awesome-icon icon="calendar-check"/> Atención</h4>-->
<!--        </div>-->
        <div class="itin-passengers-content bag-flex group row">
            <div class="col my-auto text-left p-5">
                <h1>Señores Pasajeros</h1>
                <hr>
                <h3>Si usted requiere alguno de los siguientes extras deberá comunicarse al <strong>0810-777-2633</strong> para realizar la compra.</h3>
                <ul>
                    <li>Equipaje extra.</li>
                    <li>Check In Aeropuerto.</li>
                    <li>Mejora de Valija.</li>
                    <li>Registrar Animales en Bodega.</li>
                </ul>
                <h3>Si usted tiene necesidades específicas.</h3>
                <ul>
                    <li>Pasajero con sillas de ruedas.</li>
                    <li>Menores viajando solos.</li>
                    <li>Servicio de Asistencia en Aeropuerto.</li>
                </ul>
                <p class="m-0">*Disculpe las molestias, estamos trabajando para actualizar el sitio.</p>

            </div>
        </div>
    </div>
</template>

<script>
  import { mapState } from 'vuex';
  import moment from 'moment';
  export default {
    name: 'extras-message',
    props: [],
    data: function () {
        return {}
    },
    computed: {
      ...mapState(['booking']),
    },
    methods: {
    }
  }
</script>

