<template>
  <transition name="fade">
    <div class="modal-mask">
      <div class="loadingscreen">
        <img src="@/img/loading_andes.gif"></img>
      </div>
    </div>

  </transition>
</template>

<script>
  import { mapState } from 'vuex';
  import '../css/modal.css';
  export default {
    name: 'loading-modal',
    computed: {
      ...mapState(['loading'])
    }
  }
</script>
