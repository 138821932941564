<template>
  <div>
    <div class="card shadow passenger-form">
      <div class="card-body">
        <form ref="form">
          <!-- name -->
          <div class="form-row">
            <div class="form-group col-md-6">
              <input type="checkbox" id="checkbox" v-model="checked" @change="onCheckBoxChange">
              <label for="checkbox">Copiar datos del Pasajero 1</label>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-6">
              <label>Nombre</label>
              <input type="text" id="FirstName" v-model="contact.FirstName" :class="errors.firstName?'form-control form-error': 'form-control'"/>
            </div>

            <div class="form-group col-md-6">
              <label>Apellido</label>
              <input type="text" class="form-control" id="LastName" v-model="contact.LastName" :class="errors.lastName?'form-control form-error': 'form-control'"/>
            </div>
          </div>

                    <!-- name -->
          <div class="form-row">
            <div class="form-group col-md-6">
              <label>Teléfono</label>
              <input type="text" id="telephone" v-model="contact.HomePhone" :class="errors.homePhone?'form-control form-error': 'form-control'"/>
            </div>

            <div class="form-group col-md-6">
              <label>Otro Teléfono</label>
              <input type="text" class="form-control" v-model="contact.OtherPhone" />
            </div>
          </div>

          <div class="form-row">
            <div class="form-group col">
              <label>Dirección de e-mail</label>
              <input type="email" id="mail" v-model="contact.EmailAddress" :class="errors.emailAddress?'form-control form-error': 'form-control'"/>
            </div>
          </div>


        </form>
      </div>
    </div>
  </div>

</template>

<script>
  import moment from 'moment';
  export default {
    name: 'contact-form',
    props:['contact', "onCopyFirstAdult"],
    data: function () {
      return  {
        checked: false,
        errors: {
          firstName: false,
          lastName: false,
          homePhone: false,
          emailAddress: false
        }
      }
    },
    methods: {
      onCheckBoxChange: function () {
        if (this.checked) {
          this.onCopyFirstAdult();
        }
      },
      validate: function() {
        var emailPattern = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        this.errors.firstName = !this.contact.FirstName || this.contact.FirstName.length == 0;
        this.errors.lastName = !this.contact.LastName || this.contact.LastName.length == 0;
        this.errors.homePhone = !this.contact.HomePhone || this.contact.HomePhone.length == 0;
        this.errors.emailAddress = !this.contact.EmailAddress || !emailPattern.test(this.contact.EmailAddress);

        var isValid = true;
        isValid = isValid && !this.errors.firstName;
        isValid = isValid && !this.errors.lastName;
        isValid = isValid && !this.errors.homePhone;
        isValid = isValid && !this.errors.emailAddress;

        return isValid;
      }
    }
  }
</script>
