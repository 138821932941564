<template>

  <div class="tooltip-basico tooltip-class">
    <div class="tooltip-class-title">
      <p class="text-left">
        Tarifa básica incluye:
      </p>
    </div>
    <div class="tooltip-class-content text-left">
      <p><span class="fa fa-laptop"></span> Check-in Online</p>
      <p><span class="fa fa-utensils"></span> Bebida</p>
<!--      <p><span class="seat-icon"><img src="@/img/PassengerSeat-32x32.png" style="width:20px"/></span>Cantidad limitada de asientos gratis</p>-->
      <p><span class="fa fa-exclamation-triangle"></span><strong> No incluye:</strong> Checkin en aeropuerto, despacho de equipaje, cambios o reembolsos.</p>
    </div>
    <hr>
    <div class="tooltip-class-footer text-left">
      <p>Buscas despachar equipaje y mas opciones en tu vuelo? Prueba la tarifa intermedia.</p>
    </div>
  </div>

</template>

<script>
  export default {
    name: "ClassTooltipDetail"
  }
</script>
