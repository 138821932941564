<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
  name: 'App',
  data: function () {
      return {}
  },
  computed: {
    ...mapState(['me'])
  },
  mounted: async function () {
    await this.$store.dispatch("setAirports");
  },
}
</script>
