<template>
  <div>
    <andes-header></andes-header>
    <div class="container passengersPage">
      <div class="row">
        <div class="col-md-9">
          <h3>DATOS DE LOS PASAJEROS</h3>
          <p>Por favor complete los datos de faltantes de todos los pasajeros.</p>
          <hr/>

          <div v-for="(adult, index) in adults" :key="`adult-${index}`" class="mb-5">
            <h4><font-awesome-icon icon="user"/> PASAJERO {{index + 1}} - Adulto</h4>
            <passenger-form :passenger="adult" ref="adult" type="adult"></passenger-form>
          </div>
          <div v-for="(child, index) in childs" :key="`child-${index}`" class="mb-5">
            <h4><font-awesome-icon icon="user"/> PASAJERO {{index + adults.length + 1}} - Menor</h4>
            <passenger-form :passenger="child" ref="child" type="child"></passenger-form>
          </div>
          <div v-for="(inf, index) in infants" :key="`inf-${index}`" class="mb-5">
            <h4><font-awesome-icon icon="user"/> INFANTE {{index + 1}}</h4>
            <passenger-form :passenger="inf" ref="inf" type="infant"></passenger-form>
          </div>
          <!--hr class="mb-3"/>
          <h4><font-awesome-icon icon="envelope"/> INFORMACION DE CONTACTO</h4>
          <contact-form :contact="contact" ref="contactView" class="mb-5" :onCopyFirstAdult="onCopyFirstAdult"></contact-form-->
        </div>
        <div class="col-md-3">
<!--          <summary-form :onContinue="onContinue"></summary-form>-->
          <div class="card-buttons text-center">
            <button type="button" class="btn" @click="onContinue">Continuar</button>
          </div>
        </div>
      </div>
    </div>
    <loading-modal v-show="loading"></loading-modal>
    <andes-footer></andes-footer>
  </div>
</template>

<script>
  import { passengersInfo } from '@/api/api';
  import { mapState } from 'vuex';
  import Breadcrumb from '../components/Breadcrumb';
  import SummaryForm from '../components/SummaryForm';
  import PassengerForm from '../components/passengers/PassengerForm';
  import ContactForm from "../components/passengers/ContactForm";
  import AndesHeader from "../components/AndesHeader";
  import AndesFooter from "../components/AndesFooter";
  import LoadingModal from "../components/LoadingModal";

  export default {
    name: 'passengers-uncompleted',
    components: {
      AndesHeader,
      ContactForm,
      Breadcrumb,
      SummaryForm,
      AndesFooter,
      LoadingModal,
      PassengerForm},
    data: function () {
      return {
        adults: [],
        childs: [],
        infants: [],
        contact: {"EmailAddress":"", "FirstName":"", "LastName": "", "HomePhone":"", "OtherPhone":""}
      }
    },
    computed: {
      ...mapState(['booking', 'passengerID', 'loading']),
      childCountInt: function() {
        return parseInt(this.childCount);
      },
      adultCountInt: function() {
        return parseInt(this.adultCount)
      },
      infantCountInt: function() {
        return parseInt(this.infantCount)
      }
    },
    methods: {
      onContinue: function() {
        var isValid = true;
        for (var i=0; i < this.adults.length; i++) {
          isValid = this.$refs["adult"][i].validate() && isValid;
        }

        for (i=0; i < this.childs.length; i++) {
          isValid = this.$refs["child"][i].validate() && isValid;
        }

        for (i=0; i < this.infants.length; i++) {
          isValid = this.$refs["inf"][i].validate() && isValid;
        }

        //isValid = this.$refs.contactView.validate() && isValid;
        if (isValid) {
          this.postInformation();
        }
      },
      postInformation: function () {
        this.$store.commit('loading', true);
        let checkLength = true;
        if (this.passengerID) checkLength = false;
        passengersInfo(this.booking.BookingID, [...this.adults, ...this.childs, ...this.infants], this.contact, checkLength, (result) => {
          this.$store.dispatch('updateBooking', result);
          this.$router.replace('seatmap');
          this.$store.commit('loading', false);
        });
      },
      onCopyFirstAdult: function () {
        this.contact.FirstName = this.adults[0].FirstName;
        this.contact.LastName = this.adults[0].LastName;
      }
    },
    mounted() {
      if (_.isEmpty(this.booking)){
       this.$router.replace('/');
      }
      if (this.booking) {
        this.adults = []
        this.childs = []
        this.infants = []

        for (var passenger of this.booking.Passengers) {
          if (this.passengerID && passenger.PassengerID != this.passengerID) continue;
          var p = {
            FirstName: passenger.FirstName, MiddleName: passenger.MiddleName, LastName: passenger.LastName,
            DOB: passenger.DOB, Gender: passenger.Gender, PaxType: passenger.PaxType,
            DocTypeCode: passenger.DocTypeCode, DocNumber: passenger.DocNumber, Nationality: passenger.Nationality,
            PassportDueDate:"",
            PassportCountry:"",
            PassengerID: passenger.PassengerID,
          }
          if (passenger.PaxType == 'ADT' || passenger.PaxType == 'NREV' || passenger.PaxType == 'CBUS') {
            this.adults.push(p);
          } else {
            this.childs.push(p);
          }

          for (var infant of passenger.Infants) {
            this.infants.push({
              FirstName: infant.FirstName, MiddleName: infant.MiddleName, LastName: infant.LastName,
              DOB: infant.DOB, Gender: infant.Gender,  PaxType: "INF", Nationality: infant.Nationality,
              DocTypeCode: infant.DocTypeCode, DocNumber: infant.DocNumber,})
          }
        }

        if (this.booking.ContactInformation && this.booking.ContactInformation.length > 0) {
          this.contact = {
            "EmailAddress": this.booking.ContactInformation[0].EmailAddress,
            "FirstName":this.booking.ContactInformation[0].FirstName,
            "LastName": this.booking.ContactInformation[0].LastName,
            "HomePhone": this.booking.ContactInformation[0].HomePhone,
            "OtherPhone":""
          }
        }
      }
    }

  }
</script>

