<template>
  <div>
    <andes-header></andes-header>
    <breadcrumb activePage="2"></breadcrumb>
    <div class="container">
      <div class="row">
        <div class="col-sm-9">
          <extras-message></extras-message>
        </div>
        <div class="col-sm-3">
          <summary-form :onContinue="onContinue"></summary-form>
        </div>
      </div>
    </div>
    <andes-footer></andes-footer>
  </div>
</template>
<script>
  import AndesHeader from "../components/AndesHeader";
  import Breadcrumb from "../components/Breadcrumb";
  import SummaryForm from "../components/SummaryForm";
  import LoadingModal from "../components/LoadingModal";
  import AndesFooter from "../components/AndesFooter";
  import ExtrasMessage from "../components/extras/ExtrasMessage";
  import { mapState } from 'vuex';
  export default {
    name: "ExtrasPage",
    components: {ExtrasMessage, AndesFooter, LoadingModal, SummaryForm, Breadcrumb, AndesHeader},
    computed: {
      ...mapState(['booking'])
    },
    methods: {
      onContinue: function () {
        this.$router.push('payment');
      }
    },
    mounted() {
      if (_.isEmpty(this.booking)) {
        this.$router.replace('/');
      }
    }
  }
</script>
