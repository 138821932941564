<template>
  <transition name="fade">
    <div class="modal-mask" v-if="isOpen" >
      <div class="modal-wrapper">
        <div class="modal-container session-timeout-modal">

          <div class="modal-body">
            <slot name="body">
              <div class="card">
                <div class="card-header">
                  <h4>Advertencia de sesión</h4>
                </div>
                <div class="card-body" v-if="!isExpired">
                  <h4>El tiempo de sesión termina en {{time}} segundos.</h4>
                  <p>Si desea mantener activa la reserva, por favor haga click en continuar.</p>
                </div>
                <div class="card-body" v-else>
                  <h4>Su sesión se ha terminado.</h4>
                </div>
                <div class="card-footer text-right">
                  <button class="btn btn-andes" @click="onContinue">Continuar</button>
                </div>

              </div>
            </slot>
          </div>

        </div>
      </div>
    </div>

  </transition>
</template>

<script>
  import '@/css/app.css';
  import { mapState } from 'vuex';
  import '@/css/modal.css';
  import {extendSession} from "../api/api";
  export default {
    name: 'session-timeout-modal',

    data: function() {
      return {
        time: 0,
        id:0
      }
    },
    computed: {
      ...mapState(['loading', 'booking']),
      isOpen: function () {
        return this.booking && this.booking.Status == 1 && this.time < 60;
      },
      isExpired: function () {
        return this.time < 0;
      }
    },
    methods: {
      onContinue() {
        if (this.isExpired) {
          this.$router.replace('/');
        } else {
          // extend time
          extendSession(this.booking.BookingID, this.booking.RecordLocator, result => {
            //console.log(result);
            this.time = result;
          }, result => {
            this.$router.replace('/');
          });
        }
      }
    },
    mounted: function () {
      if (window.intervalId != undefined && window.intervalId != 0) {
        clearInterval(window.intervalId);
        window.intervalId = 0;
      }

      // check status and time
      if (!this.isOpen) {
        return;
      }

      this.time = this.booking.HoldSeconds;
      var that = this;
      this.id = setInterval(function () {
        that.time = that.time - 1;
        if (that.time < 0) {
          clearInterval(that.id);
          that.id = 0;
        }
      }, 1000);

      window.intervalId = this.id;
    },
    beforeDestroy: function () {
      clearInterval(this.id);
      window.intervalId = 0;
    }
  }
</script>

