<template>
    <div class="modal fade bd-example-modal-lg printable" id="checkinTerms" tabindex="-1" role="dialog"
        data-backdrop="static" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
      <div class="modal-dialog modal-lg history-modal" role="document">
        <div class="modal-content">
          <div class="modal-body">
TERMOS E CONDIÇÕES DE TRANSPORTE DE BAGAGEM<br>
Inclui tudo o que o passageiro está autorizado a transportar, tanto bagagem de
mão como bagagem registada no porão em seu nome. Verifique os itens
proibidos/restritos para transporte. Recomendamos que identifique a bagagem
de mão e a bagagem transportada no porão com os seus dados pessoais. Em
todos os nossos voos poderá transportar bagagem para despachar no porão,
com peso máximo de 15 kg, bem como uma mala a bordo (mochila ou
bagagem de mão) que não exceda 8 kg que, caso não possa ser transportada
em a cabine.<br>
Por disponibilidade de espaço ou excesso de peso, será enviada ao armazém.
Itens proibidos a bordo Por razões de segurança, é proibido transportar na
bagagem de mão ou consigo: Armas elétricas, armas de brinquedo, aerossóis
de defesa pessoal, armas e munições, baterias que podem ser derramadas,
veículos movidos a bateria, ferramentas e ferramentas elétricas movidas a
bateria, artigos contendo oxigênio, artigos cortantes, artigos de artes marciais,
ferramentas diversas, extintores de incêndio, motores, todos tipos de
combustíveis, aerossóis inflamáveis (exceto para uso sanitário), tintas,
solventes, adesivos, pirotecnia, venenos, artigos tóxicos, líquidos corrosivos,
óleo lubrificante, cadeiras de rodas fixas ou dobráveis, alto-falantes (exceto
aqueles que cabem sob o assento, medidas aceitas 40 cm x 15 cm x 15 cm).<br>
Por razões de segurança, é proibido transportar na bagagem despachada:
Armas elétricas, armas de brinquedo, aerossóis de defesa pessoal, baterias que
podem ser derramadas, veículos movidos a bateria, itens eletrônicos contendo
baterias, baterias de reposição de íons de lítio, cigarro eletrônico, carregador
portátil, álcool em gel, modeladores de cabelo, dispositivos médicos portáteis
que funcionam com baterias, fósforos, isqueiros, extintores de incêndio,
motores, combustível para isqueiros, diluentes e qualquer líquido combustível,
aerossóis inflamáveis (exceto aqueles para uso sanitário), tintas, solventes,
adesivos, pirotecnia, produtos de limpeza em diversas formas, venenos,
líquidos corrosivos, óleo lubrificante, caixas de som.<br><br>
TERMOS E CONDIÇÕES DE REIVINDICAÇÕES DE BAGAGEM<br>
Quebra total ou parcial de bagagem Ao tomar conhecimento da quebra total ou
parcial de bagagem na chegada de um voo, a equipe da ANDES LINEAS
AEREAS e/ou TERCERIZADO deverá controlar o peso de sua bagagem e a
etiqueta correspondente, onde será verificado se possui observações
marcadas em. o momento do envio. Não será feito registro de reclamação
(PIR) para bagagens que sejam observadas, por exemplo, fechaduras
quebradas, perda de placa de identificação, sujeira ou deterioração típica do
processo de transporte, quebra por má embalagem, rodas acessórias (alça).<br>

Durante o manuseio e transferência, a bagagem poderá sofrer pequenos danos
como arranhões, amassados, cortes, rachaduras, manchas ou perda de itens
externos. Devido ao exposto, é importante que você entenda que a Empresa
não assume responsabilidade pelo seguinte: Danos por desgaste natural,
quebra de tiras, travas, fechos, acessórios e identificações de marcas, cortes,
manchas e umidade, arranhões, cortes e amassados, itens frágeis, perecíveis,
mal embalados ou inadequados para transporte, danos causados por
inspeções de autoridades (PSA, Alfândega, etc.)<br>
Da mesma forma, lembramos que você deve levar na bagagem de mão
quaisquer itens valiosos, perecíveis, frágeis e/ou eletrônicos e não colocá-los
na bagagem despachada, tais como: Joias ou relógios, prataria ou metais
preciosos, dinheiro, documentos pessoais, documentos de valor negociável ou
títulos, documentação médica ou comercial, remédios, óculos, antiguidades,
carteiras, chaves, computadores, equipamentos fotográficos, equipamentos
médicos, celulares, tablets, consoles videogames, qualquer outro item de valor
semelhante. A bagagem despachada no porão deverá conter APENAS itens de
uso pessoal (roupa, calçado, artigos de higiene pessoal). Bagagem rígida A
Andes Líneas Aéreas não assume qualquer responsabilidade por danos em
bagagens de material rígido.<br>
Itens deixados a bordo Caso você tenha esquecido algum item no avião, entre
em contato com nossa equipe do Serviço de Bagagens na área de retirada de
bagagens do aeroporto onde você chegou. Os objetos transportados a bordo
estão sob custódia do passageiro, porém o pessoal da Andes Líneas Aéreas
fará todos os esforços para tentar recuperá-los.<br>
Procedimento para conteúdo parcialmente ausente Caso o conteúdo de uma
bagagem esteja parcialmente faltando na chegada de um voo, o pessoal da
Andes Líneas Aéreas e/ou empresa terceirizada deverá controlar o peso de sua
bagagem e a etiqueta correspondente. Caso a comparação de ambos os pesos
varie, será elaborado um formulário de reclamação (PIR), para efeitos do
Departamento. O Atendimento ao Cliente determina a remuneração
correspondente. Bagagem perdida Caso falte alguma bagagem na balança de
chegada, será feita uma reclamação (PIR) da mesma e será iniciada a busca.<br>
IMPORTANTE: Todos os tipos de retirada de bagagem deverão ser feitos antes
da saída do aeroporto, sem exceção.<br>
O transporte aéreo comercial decorrente deste documento de transporte estará
sujeito às leis e acordos que lhe forem aplicáveis, nomeadamente ao decreto
1470/97, à Resolução 205/98 da Secretaria de Transportes da Nação, e no que
concerne às Condições Gerais do Contrato de Transporte Aéreo aprovado pela
Resolução 1532/98 do Ministério da Economia e Obras Públicas e Serviços
modificado pela Resolução 203 da ANAC, às Condições inseridas no bilhete de
transporte aéreo e às Condições de Transporte vigentes nos Andes.<br>
<a target="_blank" href="http://infoleg.mecon.gov.ar/infolegInternet/anexos/50000-54999/54791/norma.htm">(http://infoleg.mecon.gov.ar/infolegInternet/anexos/50000-54999/54791/norma.htm).</a>

        </div>
          <div class="modal-footer">
              <button type="button" class="btn btn-secondary" @click="close">Aceptar</button>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'checking-terms-pt',
    props: ['show'],
    data  () {
      return {
      }
    },
    mounted () {
        $('#checkinTerms').modal('show')
    },
    computed: {
    },
    methods: {
        close() {
            $('#checkinTerms').modal('hide');
            this.$emit('update:show', false);

        }
    },
  }
  </script>
  
  