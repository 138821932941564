<template>
    <div class="itin-flight">
        <div class="itin-subheader">
            <h4 class="pull-left"><font-awesome-icon icon="calendar-check"/>{{title}}</h4>
        </div>
          <div class="row">
              <div class="col-6">
              </div>
              <div class="col-3 text-center" v-for="segment of booking.Passengers[0].Segments">
                <strong>{{segment.DepartureStation}} <font-awesome-icon :icon="icon"/> {{segment.ArrivalStation}}</strong>
              </div>
          </div>
          <div class="itin-passengers-content bag-flex group row" v-for="(pax, index) in booking.Passengers">
              <div class="col-6 text-left">
                <p>{{pax.FirstName}} {{pax.MiddleName}} {{pax.LastName}}</p>
              </div>
              <div class="col-3" v-for="segment in pax.Segments">
                  <p>{{getRegisterSSRsegment(pax, 'NO', segment)}}</p>
              </div>
          </div>
    </div>
</template>

<script>
  import { mapState } from 'vuex';
  import moment from 'moment';
  export default {
    name: 'ssr-summary',
    props: ['title', 'fees', 'icon'],
    data: function () {
        return {}
    },
    computed: {
      ...mapState(['booking', 'departureSelected', 'returnSelected', 'departureFare', 'returnFare']),
    },
    methods: {
        getRegisterSSRsegment: function(pax, textFalse, segment) {
            if (segment && segment.Legs.length>0) {
                for (let ssr of segment.Legs[0].SSRs) {
                    if (this.fees.indexOf(ssr.FeeCode)>-1) {
                        return 'SI';
                    }
                }
            }
            return textFalse;
        }
    }
  }
</script>

