export const BASE_URL = process.env.SERVER_ENDPOINT;
const headers = {
  'Content-Type':'application/json',
  'Access-Control-Allow-Origin': true,
  'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE, OPTIONS'
}

const calcular = (t) => {
    let a = 45632 * parseInt(t);
    let b = parseInt(t) * 5;
    return a + '&b=' + b;
}

export const getAvailability = (params, callback) => {

  let encoded = $.param(params);

  let url = BASE_URL + '/api/booking/get_availability3?' + encoded;

  fetch(url , {
    mode:'cors',
    credentials: 'include',
    headers: headers,
  })
  .then(function(r) {
    if (r.status == 404) {
      return r.text().then(t => {
        return fetch(url + '&a='+calcular(t), {
        mode:'cors',
        credentials: 'include',
        headers: headers,
        })
      })}
    else {return r}
  }).then(function(response) {
    return response.json();
  }).then(function (result){
    callback(result);
  }) ;

};


export const createBooking = (DepartureSalesKey, ReturnSalesKey, callback) => {

  var data = {
    DepartureSalesKey
  };
  if (ReturnSalesKey != null) {
    data = {...data, ReturnSalesKey};
  }

  let url = BASE_URL + '/api/booking/create';
  fetch(url , {
    method: 'POST',
    body: JSON.stringify(data),
    headers: headers,
    mode: 'cors',
    credentials: 'include'
  }).then(function(response) {
    return response.json();
  })
  .catch(error => console.error('Error:', error))
  .then(function (result){
    callback(result);
  }) ;
}

export const passengersInfo = (bookingId, paxs, contact, checkLength, callback) => {

  var data = {
    BookingID: bookingId,
    Passengers: paxs,
    ContactInformation: contact,
    CheckLength: checkLength
  };

  let url = BASE_URL + '/api/booking/passengers_info';
  fetch(url , {
    method: 'POST',
    body: JSON.stringify(data),
    headers: headers,
    mode: 'cors',
    credentials: 'include'
  }).then(function(response) {
    return response.json();
  })
  .catch(error => console.error('Error:', error))
  .then(function (result){
    callback(result);
  }) ;

}

export const makePayment = (recordLocator, bookingId, cardToken, paymentMethod, issuerId, processingMode, installments, email, finalAmount, merchantAccountId, balanceDue, callback) => {

  var data = {
    RecordLocator: recordLocator,
    BookingID: bookingId,
    PaymentInfo: {
         cardToken, paymentMethod, issuerId, processingMode, installments, email, finalAmount, merchantAccountId, balanceDue,
        "type": "card"
    }
  };

  let url = BASE_URL + '/api/booking/payment/mp';
  fetch(url , {
    method: 'POST',
    body: JSON.stringify(data),
    headers: headers,
    mode: 'cors',
    credentials: 'include'
  }).then(function(response) {
    return response.json();
  })
  .catch(error => console.error('Error:', error))
  .then(function (result){
    callback(result);
  }) ;

}


export const resendEmailPayment = (bookingId, recordLocator,  mpPaymentId, callback) => {

  var data = {
    RecordLocator: recordLocator,
    BookingID: bookingId,
    MPPaymentId: mpPaymentId
  };

  let url = BASE_URL + '/api/booking/send_mp_cash_email';
  fetch(url , {
    method: 'POST',
    body: JSON.stringify(data),
    headers: headers,
    mode: 'cors',
    credentials: 'include'
  }).then(function(response) {
    return response.json();
  })
  .catch(error => console.error('Error:', error))
  .then(function (result){
    callback(result);
  }) ;

}


export const makeCashPayment = (recordLocator, bookingId, paymentMethod, email, finalAmount, balanceDue, callback) => {

  var data = {
    RecordLocator: recordLocator,
    BookingID: bookingId,
    PaymentInfo: {
         paymentMethod, email, finalAmount, balanceDue, "type": "ticket"
    }
  };

  let url = BASE_URL + '/api/booking/payment/mp';
  fetch(url , {
    method: 'POST',
    body: JSON.stringify(data),
    headers: headers,
    mode: 'cors',
    credentials: 'include'
  }).then(function(response) {
    return response.json();
  })
  .catch(error => console.error('Error:', error))
  .then(function (result){
    callback(result);
  }) ;

}


export const getAvailabilityByWeek = (params, callback) => {

  // let encoded = $.param({
  //   DepartureStation: "AEP", ArrivalStation: "COR",
  //   DepartureDateStart: "2019-03-29", ReturnDateStart: "2019-04-01",
  //   DepartureDateEnd: "2019-03-29", ReturnDateEnd: "2019-04-01",
  //   ADT:1
  // });

  let encoded = $.param(params);

  let url = BASE_URL + '/api/booking/get_availability3_period?' + encoded;
  fetch(url , {
    mode:'cors',
    credentials: 'include',
    headers: headers,
  }).then(function(response) {
    return response.json();
  }).then(function (result){
    callback(result);
  }) ;
};

export const getBooking = (params, callback) => {

  // let encoded = $.param({
  //   rl: "ABCDEFG",
  //   ln: "Barros"
  // });

  let encoded = $.param(params);

  let url = BASE_URL + '/api/booking/find?' + encoded;
  fetch(url , {
    mode:'cors',
    credentials: 'include',
    headers: headers,
  }).then(function(response) {
    return response.json();
  })
    .catch(error => console.error('Error:', error))
    .then(function (result){
    callback(result);
  }) ;
};

export const getSeatMap = (params, callback) => {

  /*let encoded = $.param({
    InventoryLegID: inventoryLegId
  });*/

  // let encoded = $.param(params);

  let url = BASE_URL + '/api/checkin/seat_map';
  fetch(url , {
    method: 'POST',
    body: JSON.stringify({data: params}),
    mode:'cors',
    credentials: 'include',
    headers: headers,
  }).then(function(response) {
    return response.json();
  }).then(function (result){
    callback(result);
  }) ;
};

export const postAssignSeat = (params, callback) => {

  let url = BASE_URL + '/api/checkin/assign_seat';
  fetch(url , {
    method: 'POST',
    body: JSON.stringify(params),
    mode:'cors',
    credentials: 'include',
    headers: headers,
  }).then(function(response) {
    return response.json();
  }).then(function (result){
    callback(result);
  }) ;
};

export const removeAssignSeat = (params, callback) => {

  let url = BASE_URL + '/api/checkin/remove_seat';
  fetch(url , {
    method: 'POST',
    body: JSON.stringify(params),
    mode:'cors',
    credentials: 'include',
    headers: headers,
  }).then(function(response) {
    return response.json();
  }).then(function (result){
    callback(result);
  }) ;
};


export const checkin = (params) => {

  let url = BASE_URL + '/api/checkin/checkin';
  return fetch(url , {
    method: 'POST',
    body: JSON.stringify(params),
    mode:'cors',
    credentials: 'include',
    headers: headers,
  });

};

export const extendSession = (bookingID, recordLocator, callback) => {

  let params = {
    BookingID: bookingID,
    RecordLocator: recordLocator
  }

  let url = BASE_URL + '/api/booking/extend_hold';
  fetch(url , {
    method: 'POST',
    body: JSON.stringify(params),
    mode:'cors',
    credentials: 'include',
    headers: headers,
  }).then(function(response) {
    return response.json();
  }).then(function (result){
    callback(result);
  }) ;

};

export const getStations = (callback) => {

  let url = BASE_URL + '/api/station/';
  fetch(url , {
    mode:'cors',
    credentials: 'include',
    headers: headers,
  }).then(function(response) {
    return response.json();
  }).then(function (result){
    callback(result);
  }) ;
};
