<template>

    <div class="itin-contact">
      <div class="itin-subheader">
        <h4><font-awesome-icon icon="envelope"/> Contacto</h4>
      </div>
      <div class="itin-passengers-content itin-flex group">
          <div class="text-center text-sm-left w-sm-100">
            <h5><strong>{{contact.FirstName}} {{contact.LastName}}</strong></h5>
          </div>
          <div class="text-center text-sm-left w-sm-100">
            <h5>| <strong>Teléfono: </strong>{{contact.HomePhone}}</h5>
          </div>
          <div class="text-center text-sm-left w-sm-100">
            <h5>| <strong>E-mail: </strong>{{contact.EmailAddress}}</h5>
          </div>
      </div>
    </div>

</template>

<script>
  import { mapState } from 'vuex';
  export default {
    name: 'contact-summary',
    computed: {
      ...mapState(['booking'])
    },
    data: function () {
      return {
        contact: {}
      }
    },
    mounted() {
      if (this.booking) {
        this.contact = {
          "EmailAddress": this.booking.ContactInformation[0].EmailAddress,
          "FirstName":this.booking.ContactInformation[0].FirstName,
          "LastName": this.booking.ContactInformation[0].LastName,
          "HomePhone": this.booking.ContactInformation[0].HomePhone,
          "OtherPhone":""
        }
      }
    }
  }
</script>

