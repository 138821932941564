<template>
    <div class="itin-flight">
        <div class="itin-subheader">
            <h4 class="pull-left"><font-awesome-icon icon="calendar-check"/> {{title}}</h4>
        </div>
          <div class="itin-passengers-content bag-flex group row">
              <div class="col-3 my-auto text-left" >
                  <p>{{getRegisterExtraFee}}</p>
              </div>
              <div class="col-9" >
                  <img class="extrafeee-img" :src="getImgUrl">
              </div>
          </div>
    </div>
</template>

<script>
  import { mapState } from 'vuex';
  import moment from 'moment';
  export default {
    name: 'extrafee-summary',
    props: ['title', 'fees', 'textFalse', 'image'],
    data: function () {
        return {}
    },
    computed: {
      ...mapState(['booking']),
        getRegisterExtraFee: function(pax, textFalse) {
            for (let fee of this.booking.Passengers[0].Fees) {
                if (this.fees.indexOf(fee.FeeCode)>-1) {
                    if (fee.Charges.length>0) {
                        return fee.Charges[0].CurrencyCode + ' ' + fee.Charges[0].ChargeAmount;
                    }
                }
            }
            return this.textFalse;
        },
        getImgUrl: function() {
            var images = require.context('@/img/', false)
            return images('./' + this.image)
        }
    },
    methods: {
    }
  }
</script>

