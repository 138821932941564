
export const normalizeText = (s) => {

    if (!s) return s;
    if (s.normalize != undefined) {
        s = s.normalize ("NFKD");
    }
    s = s.toString().replace(/æ/g, "ae");
    s = s.replace(/[\'`´]/g, "");
    //s = s.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') //all chars literal
    return s.replace(/[\u0300-\u036F]/g, "");

}
