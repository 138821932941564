<template>
    <div class="modal fade bd-example-modal-lg printable" id="checkinTerms" tabindex="-1" role="dialog"
        data-backdrop="static" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
      <div class="modal-dialog modal-lg history-modal" role="document">
        <div class="modal-content">
          <div class="modal-body">
TÉRMINOS Y CONDICIONES DEL TRANSPORTE DE EQUIPAJES<br>
Comprende todo lo que el pasajero está autorizado a transportar, tanto el
equipaje de mano, como aquel que sea registrado en bodega a su nombre.
Verifica los elementos prohibidos/restringidos para su transporte.
Te recomendamos identificar con tus datos personales, el equipaje de mano y
el despachado en bodega.<br>
En todos nuestros vuelos podrás llevar un equipaje a despachar en bodega, de
un peso máximo de 15 kg, además de un bolso abordo (mochila o carry on)
que no excedan los 8 kg que, en caso de no poder ser transportado en la
cabina por disponibilidad de espacio o exceso de peso, se enviará a la bodega.
Artículos prohibidos a bordo<br>
Por razones de seguridad se prohíbe transportar en el equipaje de mano y en
su persona lo siguiente:<br>
Armas eléctricas, armas de juguete, aerosoles de defensa personal,
armamento y municiones, baterías derramables, vehículos impulsados
por baterías, herramientas impulsadas por baterías y herramientas
eléctricas, artículos que contengan oxígeno, artículos con filo, artículos
para artes marciales, herramientas varias, extintores, motores, todo tipo
de combustible, aerosoles inflamables (excepto los que son de uso de
toilette), pinturas, solventes, adhesivos, pirotecnia, venenos, artículos
tóxicos, líquidos corrosivos, aceite lubricante, sillas de ruedas fijas o
plegables, parlantes (excepto que entren debajo del asiento, medidas
aceptadas 40 cm x 15 cm x 15cm)<br>
Por razones de seguridad se prohíbe transportar en el equipaje de bodega lo
siguiente:<br>
Armas eléctricas, armas de juguete, aerosoles de defensa personal,
baterías derramables, vehículos impulsados por baterías, artículos
electrónicos que contienen baterías, baterías de repuesto Ion Litio,
cigarrillo electrónico, cargador portátil, alcohol en gel, rizadores de
cabello, dispositivos médicos portátiles que operen con pila o batería,
fósforos, encendedores, extintores, motores, combustible para
encendedores, diluyentes para pintura y cualquier líquido combustible,
aerosoles inflamables (excepto los que son de uso de toilette), pinturas,
solventes, adhesivos, pirotecnia, artículos de limpieza de diversas
formas, venenos, líquidos corrosivos, aceite lubricantes, parlantes.<br><br>

TERMINOS Y CONDICIONES DE RECLAMOS DE EQUIPAJES<br>
Rotura total o parcial del equipaje<br>
Al tomar conocimiento de la rotura total o parcial de un equipaje al arribo de un
vuelo, el personal de ANDES LINEAS AEREAS y/o TERCERIZADO, deberá
controlar el peso de su equipaje y el marbete correspondiente, en donde se
verificará si el mismo posee observaciones marcadas al momento del
embarque.<br>
No se tomará registro de reclamo (PIR) en aquellos equipajes que se observe,
por ejemplo rotura de candados, pérdida de chapa de identificación, suciedad o
deterioro propios del proceso de transporte, roturas por mal embalaje, ruedas
accesorios (manija)<br>
Durante la manipulación y traslado, el equipaje puede sufrir daños menores
tales como raspaduras, abolladuras, cortes, rajaduras, manchas o pérdida de
elementos externos. Por lo expresado anteriormente es importante que Ud
comprenda que la Compañía no asume responsabilidad por lo siguiente:
Daños por desgaste natural, rotura de correas, candados, cierres,
accesorios e identificaciones de marca, cortes, manchas y mojaduras,
rayones, tajos y abolladuras, artículos frágiles, perecederos, mal
empaquetados o no aptos para el transporte, daños ocasionados por
inspecciones de autoridades (PSA, Aduana, etc.)<br>
Asimismo, le recordamos llevar en su equipaje de mano cualquier elemento de
valor, perecedero, frágil y/o electrónico y no colocarlos en el equipaje
despachado, como por ejemplo:<br>
Joyas o relojes, platería o metales preciosos, dinero, documentos
personales, documentos de valor negociable o títulos, documentación
médica o comercial, medicina, lentes, antigüedades, billeteras, llaves,
computadoras, equipo fotográfico, equipo médico, celulares, tablets,
consola de videojuegos, cualquier otro artículo de similar valor.
El equipaje registrado en bodega debe tener SOLO elementos de uso personal
(ropa, calzado, elementos de higiene personal).<br>
Equipajes rígidos<br>
Andes Líneas Aéreas no asume ninguna responsabilidad por daños en
equipajes de material rígido.<br>

Artículos olvidados a bordo<br>
Si ha olvidado algún artículo arriba del avión, póngase en contacto con nuestro
personal de Servicio de Equipajes dentro de la zona de retiro de equipajes del
aeropuerto donde haya arribado.<br>
Los objetos llevados a bordo se encuentran bajo la guarda del pasajero, no
obstante ello, personal de Andes Líneas Aéreas realizará el máximo esfuerzo
para tratar de recuperarlos.<br>
Procedimiento por faltante parcial de contenido<br>
Ante el faltante parcial del contenido de un equipaje al arribo de un vuelo, el
personal de Andes Líneas Aéreas y/o empresa tercerizada, deberá controlar el
peso de su equipaje y el marbete correspondiente. Si la comparación de ambos
pesos varía, se procederá a confeccionar un formulario de reclamo (PIR), a los
efectos de que el Depto. de Atención al Cliente determine la indemnización que
correspondiere.<br>
Equipaje faltante<br>
Si faltare algún equipaje en la escala de arribo, se confeccionará el reclamo
(PIR) del mismo y se dará comienzo a la búsqueda.<br>
IMPORTANTE: Todo tipo de reclamo de equipaje debe realizarse antes de
retirarse del aeropuerto, sin excepción.<br>
El transporte aerocomercial emergente de este documento de transporte se
sujetará a las leyes y convenios aplicables al mismo, en particular el decreto
1470/97, Resolución 205/98 del Secretario de Transporte de la Nación, y en lo
pertinente a las Condiciones Generales del Contrato de Transporte Aéreo
aprobadas por la Resolución 1532/98 del Ministerio de Economía y Obras y
Servicios Públicos modificada por la Resolución 203 de la ANAC, a las
Condiciones insertadas en el billete de transporte aéreo y a las Condiciones de
Transporte vigentes en Andes.<br>
<a target="_blank" href="http://infoleg.mecon.gov.ar/infolegInternet/anexos/50000-54999/54791/norma.htm">(http://infoleg.mecon.gov.ar/infolegInternet/anexos/50000-54999/54791/norma.htm).</a>

        </div>
          <div class="modal-footer">
              <button type="button" class="btn btn-secondary" @click="close">Aceptar</button>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'checking-terms',
    props: ['show'],
    data  () {
      return {
      }
    },
    mounted () {
        $('#checkinTerms').modal('show')
    },
    computed: {
    },
    methods: {
        close() {
            $('#checkinTerms').modal('hide');
            this.$emit('update:show', false);

        }
    },
  }
  </script>
  
  