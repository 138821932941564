<template>
    <div class="card shadow passenger-form">
      <div class="card-body">
        <form ref="form">
          <!-- name -->
          <div class="form-row">
            <div class="form-group col-md-6">
              <label>Apellido</label>
              <input type="text" class="form-control" id="LastName" v-model="passenger.LastName" :class="errors.lastName?'form-control form-error': 'form-control'"/>
            </div>
            <div class="form-group col-md-3">
              <label>Primer Nombre</label>
              <input type="text" id="FirstName" v-model="passenger.FirstName" :class="errors.firstName?'form-control form-error': 'form-control'"/>
            </div>
            <div class="form-group col-md-3">
              <label>Segundo Nombre</label>
              <input type="text" class="form-control" id="middleName" v-model="passenger.MiddleName"/>
            </div>
          </div>

          <!-- second line -->
          <div class="form-row">
            <div class="col-md-6">
              <date-selector :date.sync="passenger.DOB" label="Fecha de Nacimiento" ref="birthDate"
                :min-year="minYear" :max-year="maxYear" :error="errors.birthDate"></date-selector>
            </div>

            <div class="form-group col-md-2">
              <label>Género</label>
              <select class="form-control" id="gender" v-model="passenger.Gender"
              :class="errors.gender?'form-control form-error': 'form-control'">
                <option :value="-1">Seleccione</option>
                <option :value="'MALE'">Masculino</option>
                <option :value="'FEMALE'">Femenino</option>
              </select>
            </div>
            <div class="form-group col-md-4">
              <label>Nacionalidad</label>
              <select class="form-control" id="nationality" v-model="passenger.Nationality"
              :class="errors.nationality?'form-control form-error': 'form-control'">
                <option value="">Seleccione</option>
                <option value="AR" selected="selected">Argentina</option>
                <option value="AT">Austria, Republic of</option>
                <option value="AU">Australia, Commonwealth of</option>
                <option value="BE">Belgium, Kingdom of</option>
                <option value="BG">Bulgaria, People's Republic of</option>
                <option value="BO">Bolivia</option>
                <option value="BR">Brasil</option>
                <option value="BY">Belarus</option>
                <option value="CA">Canada</option>
                <option value="CH">Switzerland, Swiss Confederation</option>
                <option value="CL">Chile</option>
                <option value="CN">China, People's Republic of</option>
                <option value="CO">Colombia</option>
                <option value="CR">Costa Rica, Republic of</option>
                <option value="CU">Cuba, Republic of</option>
                <option value="CZ">Czech Republic</option>
                <option value="DE">Germany</option>
                <option value="DK">Denmark, Kingdom of</option>
                <option value="DO">Dominican Republic</option>
                <option value="EC">Ecuador</option>
                <option value="EE">Estonia</option>
                <option value="ES">España</option>
                <option value="FI">Finland, Republic of</option>
                <option value="FR">France, French Republic</option>
                <option value="GB">United Kingdom of Great Britain &amp; N. Ireland</option>
                <option value="GR">Greece, Hellenic Republic</option>
                <option value="GT">Guatemala, Republic of</option>
                <option value="HN">Honduras, Republic of</option>
                <option value="HR">Hrvatska (Croatia)</option>
                <option value="HU">Hungary, Hungarian People's Republic</option>
                <option value="ID">Indonesia, Republic of</option>
                <option value="IE">Ireland</option>
                <option value="IL">Israel, State of</option>
                <option value="IN">India, Republic of</option>
                <option value="IS">Iceland, Republic of</option>
                <option value="IT">Italy, Italian Republic</option>
                <option value="JP">Japan</option>
                <option value="LT">Lithuania</option>
                <option value="LV">Latvia</option>
                <option value="MX">Mexico, United Mexican States</option>
                <option value="NI">Nicaragua, Republic of</option>
                <option value="NL">Netherlands, Kingdom of the</option>
                <option value="NO">Norway, Kingdom of</option>
                <option value="NZ">New Zealand</option>
                <option value="PA">Panama, Republic of</option>
                <option value="PE">Peru</option>
                <option value="PL">Poland, Polish People's Republic</option>
                <option value="PR">Puerto Rico</option>
                <option value="PT">Portugal, Portuguese Republic</option>
                <option value="PY">Paraguay, Republic of</option>
                <option value="RO">Romania, Socialist Republic of</option>
                <option value="RS">Serbia</option>
                <option value="RU">Russian Federation</option>
                <option value="SE">Sweden, Kingdom of</option>
                <option value="SI">Slovenia</option>
                <option value="SK">Slovakia (Slovak Republic)</option>
                <option value="SV">El Salvador, Republic of</option>
                <option value="TR">Turkey, Republic of</option>
                <option value="TW">Taiwan, Province of China</option>
                <option value="UA">Ukraine</option>
                <option value="US">Estados Unidos</option>
                <option value="UY">Uruguay</option>
                <option value="VE">Venezuela, Bolivarian Republic of</option>
                <option value="ZZ">Otros</option>
              </select>
            </div>
          </div>

          <!--third line-->
          <div class="form-row">
            <div class="form-group col-md-6">
              <label>Tipo de Documento</label>
              <select class="form-control" id="doc_type" v-model="passenger.DocTypeCode"
              :class="errors.docType?'form-control form-error': 'form-control'">
                <option value="">Seleccione</option>
                <option value="DNI" selected="selected">DNI</option>
                <option value="P">Pasaporte</option>
              </select>
            </div>
            <div class="form-group col-md-6">
              <label>Número</label>
              <input type="text" class="form-control" id="doc" v-model="passenger.DocNumber"
              :class="errors.docNumber?'form-control form-error': 'form-control'"/>
            </div>
          </div>

          <div class="form-row" v-if="isPassport">
            <div class="col-md-6">
              <date-selector :date.sync="passenger.PassportDueDate" label="Fecha de Vencimiento" ref="passportDueDate"
              :min-year="minPassportYear" :max-year="maxPassportYear" :error="errors.passportDueDate"></date-selector>
            </div>
            <div class="form-group col-md-6">
              <label>Nacionalidad</label>
              <select class="form-control" v-model="passenger.PassportCountry"
              :class="errors.passportCountry?'form-control form-error': 'form-control'">
                <option value="">Seleccione</option>
                <option value="AR" selected="selected">Argentina</option>
                <option value="AT">Austria, Republic of</option>
                <option value="AU">Australia, Commonwealth of</option>
                <option value="BE">Belgium, Kingdom of</option>
                <option value="BG">Bulgaria, People's Republic of</option>
                <option value="BO">Bolivia</option>
                <option value="BR">Brasil</option>
                <option value="BY">Belarus</option>
                <option value="CA">Canada</option>
                <option value="CH">Switzerland, Swiss Confederation</option>
                <option value="CL">Chile</option>
                <option value="CN">China, People's Republic of</option>
                <option value="CO">Colombia</option>
                <option value="CR">Costa Rica, Republic of</option>
                <option value="CU">Cuba, Republic of</option>
                <option value="CZ">Czech Republic</option>
                <option value="DE">Germany</option>
                <option value="DK">Denmark, Kingdom of</option>
                <option value="DO">Dominican Republic</option>
                <option value="EC">Ecuador</option>
                <option value="EE">Estonia</option>
                <option value="ES">España</option>
                <option value="FI">Finland, Republic of</option>
                <option value="FR">France, French Republic</option>
                <option value="GB">United Kingdom of Great Britain &amp; N. Ireland</option>
                <option value="GR">Greece, Hellenic Republic</option>
                <option value="GT">Guatemala, Republic of</option>
                <option value="HN">Honduras, Republic of</option>
                <option value="HR">Hrvatska (Croatia)</option>
                <option value="HU">Hungary, Hungarian People's Republic</option>
                <option value="ID">Indonesia, Republic of</option>
                <option value="IE">Ireland</option>
                <option value="IL">Israel, State of</option>
                <option value="IN">India, Republic of</option>
                <option value="IS">Iceland, Republic of</option>
                <option value="IT">Italy, Italian Republic</option>
                <option value="JP">Japan</option>
                <option value="LT">Lithuania</option>
                <option value="LV">Latvia</option>
                <option value="MX">Mexico, United Mexican States</option>
                <option value="NI">Nicaragua, Republic of</option>
                <option value="NL">Netherlands, Kingdom of the</option>
                <option value="NO">Norway, Kingdom of</option>
                <option value="NZ">New Zealand</option>
                <option value="PA">Panama, Republic of</option>
                <option value="PE">Peru</option>
                <option value="PL">Poland, Polish People's Republic</option>
                <option value="PR">Puerto Rico</option>
                <option value="PT">Portugal, Portuguese Republic</option>
                <option value="PY">Paraguay, Republic of</option>
                <option value="RO">Romania, Socialist Republic of</option>
                <option value="RS">Serbia</option>
                <option value="RU">Russian Federation</option>
                <option value="SE">Sweden, Kingdom of</option>
                <option value="SI">Slovenia</option>
                <option value="SK">Slovakia (Slovak Republic)</option>
                <option value="SV">El Salvador, Republic of</option>
                <option value="TR">Turkey, Republic of</option>
                <option value="TW">Taiwan, Province of China</option>
                <option value="UA">Ukraine</option>
                <option value="US">Estados Unidos</option>
                <option value="UY">Uruguay</option>
                <option value="VE">Venezuela, Bolivarian Republic of</option>
                <option value="ZZ">Otros</option>
              </select>
            </div>
          </div>

        </form>
      </div>
    </div>

</template>

<script>
  import { mapState } from 'vuex';
  import moment from 'moment';
  import DateSelector from "../DateSelector";
  export default {
    components: {DateSelector},
    name: 'passenger-form',
    props:['passenger', 'type'],
    data: function () {
      return  {
        nationality: '',
        errors: {
          firstName: false,
          lastName: false,
          birthDate: false,
          gender: false,
          nationality: false,
          docType: false,
          docNumber: false,
          passportDueDate: false,
          passportCountry: false
        }
      }
    },
    computed: {
      ...mapState(['booking']),
      minYear: function() {
        if (this.type == 'child') {
          return moment().year() - 12;
        } else if (this.type == 'infant') {
          return moment().year() - 2;
        }
        return moment().year() - 110;
      },
      maxYear: function() {
        if (this.type == 'child') {
          return moment().year() - 2;
        } else if (this.type == 'infant') {
          return moment().year();
        }
        return moment().year() - 12;
      },
      isPassport: function() {
        return this.passenger.DocTypeCode == 'P';
      },
      minPassportYear: function() {
        return moment().year();
      },
      maxPassportYear: function() {
        return moment().year() + 20;
      }
    },
    methods: {
      validate: function() {
        this.errors.firstName = !this.passenger.FirstName || this.passenger.FirstName.length == 0;
        this.errors.lastName = !this.passenger.LastName || this.passenger.LastName.length == 0;
        this.errors.birthDate = !this.$refs.birthDate.validate();
        this.errors.gender = this.passenger.Gender == null || this.passenger.Gender == -1;
        this.errors.docType = !this.passenger.DocTypeCode || this.passenger.DocTypeCode.length == 0;
        this.errors.docNumber = !this.passenger.DocNumber || this.passenger.DocNumber.length == 0;
        this.errors.nationality = !this.passenger.Nationality || this.passenger.Nationality.length == 0;
        this.errors.passportDueDate = this.passenger.DocTypeCode == 'P'? !this.$refs.passportDueDate.validate(): false;
        this.errors.passportCountry = this.passenger.DocTypeCode == 'P'? this.passenger.PassportCountry.length == 0: false;

        if (!this.errors.birthDate) {
          // check date is in the right range
          let bd = moment(this.passenger.DOB, "YYYY-MM-DD");
          if (this.type == 'child') {
            let minlimit = moment(this.booking.Passengers[0].Segments[0].DepartureDate, "YYYY-MM-DD").subtract(2, 'year');
            let maxlimit = moment(this.booking.Passengers[0].Segments[0].DepartureDate, "YYYY-MM-DD").subtract(12, 'year');
            this.errors.birthDate = bd.isAfter(minlimit) || bd.isBefore(maxlimit);
          } else if (this.type == 'infant') {
            let bd = moment(this.passenger.DOB, "YYYY-MM-DD");
            let maxlimit = moment(this.booking.Passengers[0].Segments[0].DepartureDate, "YYYY-MM-DD").subtract(2, 'year');
            this.errors.birthDate = bd.isBefore(maxlimit);
          } else {
            let maxlimit = moment(this.booking.Passengers[0].Segments[0].DepartureDate, "YYYY-MM-DD").subtract(12, 'year');
            this.errors.birthDate = bd.isAfter(maxlimit);
            //console.log(bd.isAfter(maxlimit), bd, maxlimit);
          }
        }
        // if (this.isPassport && !this.errors.passportDueDate) {
        //   // check date is in the right range
        //   let dueDate = moment(this.passenger.PassportDueDate, "YYYY-MM-DD");
        //   let departureDate = moment(this.booking.Passengers[0].Segments[0].DepartureDate, "YYYY-MM-DD");
        //
        //   // esta vencido para el dia del viaje?
        //   this.errors.passportDueDate = dueDate.isBefore(departureDate);
        // }

        var isValid = true;
        isValid = isValid && !this.errors.firstName;
        isValid = isValid && !this.errors.lastName;
        isValid = isValid && !this.errors.birthDate;
        isValid = isValid && !this.errors.gender;
        isValid = isValid && !this.errors.docType;
        isValid = isValid && !this.errors.docNumber;
        isValid = isValid && !this.errors.nationality;
        isValid = isValid && !this.errors.passportDueDate;
        isValid = isValid && !this.errors.passportCountry;

        return isValid;
      }
    }
  }
</script>
