<template>
    <div class="plane">
      <div class="cockpit">
        <h1>Elija Asiento</h1>
      </div>

      <div class="exit exit--front fuselage"/>
      <div class="bathroom fuselage">
        <span class="bthr1"><i class="fas fa-restroom"></i></span>
      </div>
      <ol class="cabin fuselage">
        <li :class="index == 14? 'row-- emergenseat':index ==15?'row-- emergenseat padding-bottom-10':'row--'" v-for="index in 31">
          <span v-if="index == 14">< Salidas de emergencia > </span>
          <ol class="seats" type="A">

            <li class="seat">
              <input type="checkbox" :disabled="isNotAvailable(index, 'A')" :checked="isCurrentPax(index, 'A')" />
              <label @click="assignSeat(index, 'A')">{{index}}A</label>
            </li>
            <li class="seat">
              <input type="checkbox" :disabled="isNotAvailable(index, 'B')" :checked="isCurrentPax(index, 'B')" />
              <label @click="assignSeat(index, 'B')" >{{index}}B</label>
            </li>
            <li class="seat">
              <input type="checkbox" :disabled="isNotAvailable(index, 'C')" :checked="isCurrentPax(index, 'C')" />
              <label @click="assignSeat(index, 'C')" >{{index}}C</label>
            </li>
            <li class="seat">
              <input type="checkbox" :disabled="isNotAvailable(index, 'D')" :checked="isCurrentPax(index, 'D')"/>
              <label @click="assignSeat(index, 'D')">{{index}}D</label>
            </li>

            <li class="seat">
              <input type="checkbox" :disabled="isNotAvailable(index, 'E')" :checked="isCurrentPax(index, 'E')"/>
              <label @click="assignSeat(index, 'E')">{{index}}E</label>
            </li>
            <li class="seat">
              <input type="checkbox" :disabled="isNotAvailable(index, 'F')" :checked="isCurrentPax(index, 'F')"/>
              <label @click="assignSeat(index, 'F')">{{index}}F</label>
            </li>
          </ol>
        </li>
      </ol>
      <div class="exit exit--back fuselage"/>

    </div>
</template>

<script>
  import moment from 'moment';
  export default {
    name: "Seatmap-plane",
    props: ["seatMap", "currentPax"],
    methods: {
      isCurrentPax: function(row, letter) {

        let key = row + letter;

        if (this.currentPax != null) {
          return key === this.currentPax.paxLeg.UnitDesignator;
        }

        return false;

        // console.log('isOccupied', key);
      },
      isNotAvailable: function(row, letter) {
        let key = row + letter;

        if (row == 1 || row == 14 || row == 15) {
          return true;
        }
        if (key in this.seatMap) {
          return this.seatMap[key].Status !== 'AVAILABLE'
                  || (this.hasInfant && !this.seatMap[key].AllowInfant)
                  || (this.cantPaxBeInExitSeat && this.seatMap[key].ExitRow) ;
        }

        return true;
      },
      assignSeat: function(row, letter) {

        let key = row + letter;
        if (this.isNotAvailable(row, letter)) {
          return;
        }

        this.$emit('assignSeat', this.currentPax, key);
      },
    },
    computed: {
      cantPaxBeInExitSeat: function() {
        // console.log(this.currentPax.pax.DOB, age);
        let age = moment().diff(moment(this.currentPax.pax.DOB, "YYYY-DD-MM"), 'years');
        return age < 14 || age > 70 || this.hasInfant;
      },
      hasInfant: function() {
        return _.has(this.currentPax.pax, 'Infant') && !_.isNull(this.currentPax.pax.Infant) && this.currentPax.pax.Infant;
      }
    }
  }
</script>

<style scoped>

</style>