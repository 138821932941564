<template>
    <div class="itin-flight">
        <div class="itin-subheader">
            <h4 class="pull-left"><font-awesome-icon icon="calendar-check"/> ANDES CLUB</h4>
        </div>
          <div class="itin-passengers-content bag-flex group row" v-for="(pax, index) in booking.Passengers">
              <div class="col-6 col-sm-4 my-auto text-left">
                <p>{{pax.FirstName}} {{pax.MiddleName}} {{pax.LastName}}</p>
              </div>
              <div class="col-6 offset-sm-2 my-auto">
                  <p>{{getRegisterSSR(pax, 'NO')}}</p>
              </div>
          </div>
    </div>
</template>

<script>
  import { mapState } from 'vuex';
  import moment from 'moment';
  export default {
    name: 'andesclub-summary',
    data: function () {
        return {
            fees: ['CLUB'],
        }
    },
    computed: {
      ...mapState(['booking']),
    },
    methods: {
        getRegisterSSR: function(pax, textFalse) {
            let segment = pax.Segments[0];
            if (segment && segment.Legs.length>0) {
                for (let ssr of segment.Legs[0].SSRs) {
                    if (this.fees.indexOf(ssr.FeeCode)>-1) {
                        return 'SI';
                    }
                }
            }
            return textFalse;
        }
    }
  }
</script>

