<template>

  <div class="tooltip-premium tooltip-class">
    <div class="tooltip-class-title">
      <p class="text-left">
        Tarifa premium incluye:
      </p>
    </div>
    <div class="tooltip-class-content text-left">
      <p><span class="fa fa-laptop"></span> Check-in Online</p>
      <p><span class="fa fa-utensils"></span> Bebida</p>
<!--      <p><span class="seat-icon"><img src="@/img/PassengerSeat-32x32.png" style="width:20px"/></span> Todos los asientos gratis</p>-->
      <p><span class="fa fa-briefcase"></span> 2 valijas de 23kg cada una</p>
      <p><span class="fa fa-sync-alt"></span> Cambio o reembolso de tu viaje.</p>
      <p><span class="fa fa-check-circle"></span><strong> Incluye:</strong> Checkin en aeropuerto.</p>
    </div>

  </div>

</template>

<script>
  export default {
    name: "PremiumClassTooltipDetail"
  }
</script>
