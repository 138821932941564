<template>
    <header>
      <div class="container">
        <div class="row">
          <a href="https://www.andesonline.com">
            <!--img src="@/img/andes_logo.jpg"/-->
            <img class="main-logo img-fluid" src="@/img/andes_logo.jpg" alt="logo-andes">
          </a>
        </div>
      </div>
    </header>

</template>

<script>
  export default {
    name: 'andes-header',
  }
</script>

