<template>
  <div>
    <andes-header></andes-header>
    <div class="container mt-3">
      <div class="row">
        <div class="col px-0 py-4">
          <booking-search></booking-search>
        </div>
      </div>

    </div>
    <loading-modal v-show="loading"></loading-modal>
    <andes-footer></andes-footer>
  </div>
</template>

<script>
  import moment from 'moment';
  import { mapState } from 'vuex';
  import LoadingModal from "../components/LoadingModal";
  import AndesHeader from "../components/AndesHeader";
  import BookingSearch from "../components/search/BookingDateSearch";
  import AndesFooter from "../components/AndesFooter";
  export default {
    components: {
      AndesHeader,
      BookingSearch,
      LoadingModal,
      AndesFooter,
      },
    name: 'checkin-search',
    computed: {
      ...mapState(['loading']),
    },

    data: function () {
      return {
      }
    },

    mounted: function () {
      this.resolvePath();

      // si es ida y vuelta tomo dd2

    },
    methods: {
        resolvePath: function() {
            let loc = window.location;
            if (loc.pathname=='/BookingSearch') {
                let search = loc.search;
                let newpath = origin + '/#/BookingSearch' + search;
                window.location.href = newpath;
            }

        }
    }
  }
</script>

