import Vue from 'vue'
import Vuex from 'vuex';
var moment = require('moment');
Vue.use(Vuex);
import { getStations } from '@/api/api';



const store = new Vuex.Store({
  state: {
    loading: false,
    flights: {},
    departureSelected: null,
    departureFare: null,
    returnSelected: null,
    returnFare: null,
    adultCount:1,
    childCount:0,
    infantCount:0,
    querySearch:{},
    booking: {},
    lastName: null,
    dni: null,
    passengerID: null,
    bookingSearch:{},
    weekFares: {departure_flight: {}, return_flight: {}},
    checkboxTerms: null,
    termsError: false,
    installmentSelected: {},
    checkinFlight: null,
    segmentsToCheck: [],
    airports: [],
    /*airports: [
      {name: 'Buenos Aires', id:'AEP', country: 'Argentina'},
      {name: 'Buenos Aires', id:'EZE', country: 'Argentina'},
      {name: 'Iguazu', id:'IGR', country: 'Argentina'},
      {name: 'Salta', id:'SLA', country: 'Argentina'},
      {name: 'Cordoba', id:'COR', country: 'Argentina'},
      {name: 'Jujuy', id:'JUJ', country: 'Argentina'},
      {name: 'Puerto Madryn', id:'PMY', country: 'Argentina'},
      {name: 'Bariloche', id:'BRC', country: 'Argentina'},
      {name: 'Comodoro Rivadavia', id:'CRD', country: 'Argentina'},
      // {name: 'Punta del Este', id:'PDP', country: 'Uruguay'},
    ]*/
  },
  mutations: {
    flights(state, flights) {
      state.flights = flights;
    },
    departureSelected(state, flight) {
        // state.departureSelected = Object.assign({}, flight);
      state.departureSelected = flight;
    },
    returnSelected(state, flight) {
      state.returnSelected = flight;
    },
    departureFare(state, fare) {
        // state.departureSelected = Object.assign({}, flight);
      state.departureFare = fare;
    },
    returnFare(state, fare) {
      state.returnFare = fare;
    },
    booking(state, value) {
      state.booking = value;
    },
    lastName(state, {lastName, dni}) {
      state.lastName = lastName.replace(/ /g, '');
      state.dni = dni.replace(/ /g, '');;
    },
    setPassengerID(state, value) {
      state.passengerID = value;
    },
    bookingSearch(state, value) {
      state.bookingSearch = value;
    },
    adultCount(state, value) {
      state.adultCount = parseInt(value);
    },
    childCount(state, value) {
      state.childCount = parseInt(value);
    },
    infantCount(state, value) {
      state.infantCount = parseInt(value);
    },
    loading(state, value) {
      state.loading = value;
    },
    querySearch(state, value) {
      state.querySearch = value;
    },
    weekFares(state, value) {
      state.weekFares = value;
    },
    checkboxTerms(state, value) {
      state.checkboxTerms = value;
    },
    termsError(state, value) {
      state.termsError = value;
    },
    checkinFlight(state, value) {
      state.checkinFlight = value;
    },
    boardingFlight(state, value) {
      state.boardingFlight = value;
    },
    installmentSelected(state, value) {
      state.installmentSelected = value;
    },
    clearSegmentToCheck(state) {
      state.segmentsToCheck = []
    },
    addSegmentToCheck(state, value) {
      let i = state.segmentsToCheck.indexOf(value);
      if (i==-1) {
        state.segmentsToCheck.push(value)
      }
    },
    removeSegmentToCheck(state, value) {
      let i = state.segmentsToCheck.indexOf(value);
      if (i>-1) {
        state.segmentsToCheck.splice(i, 1);
      }
    },
    updateSeats(state, paxs) {
      /* [{pax, segId, paxLeg}] */
      for (var pax of paxs) {
        for (var bookingPax of state.booking.Passengers) {
          for (var seg of bookingPax.Segments) {
            if (seg.SegmentID == pax.segId) {
              seg.Legs[0] = pax.paxLeg;
            }
          }
        }
      }
    },
    setAirports (state, airports) {
        let res = [];
        for (let a of airports) {
            res.push({name: a.CityName, id: a.id, country: 'Argentina'});
        }
        state.airports = res;
    }

  },
  getters: {
    me: state => {
      return state.me;
    },
  },
  actions: {
    updateFlights({commit, state}, flights) {
      var list = [];
      for (var f in flights.departure_flight) {
        list.push({...flights.departure_flight[f], FlightNumber:f});
      }

      if (list.length > 0) {
        var selectedFlight = list[0];
        var selectedFare = selectedFlight.Fares.sort((a, b) => a.Amount - b.Amount)[0];
        commit('departureSelected', selectedFlight);
        commit('departureFare', selectedFare);
      } else {
        commit('departureSelected', null);
        commit('departureFare', null);
        console.log('No departure flights available');
      }

      list = []
      for (var f in flights.return_flight) {
        list.push({...flights.return_flight[f], FlightNumber:f});
      }

      if (list.length > 0) {
        var selectedFlight = list[0];
        var selectedFare = selectedFlight.Fares.sort((a, b) => a.Amount - b.Amount)[0];
        commit('returnSelected', selectedFlight);
        commit('returnFare', selectedFare);
      } else if (flights.hasOwnProperty("return_flight")) {
        commit('returnSelected', null);
        commit('returnFare', null);
        console.log('No return flights available');
      }

      commit('flights', flights);

      // commit(departureSelected, flights.departure_flights.);
    },
    updateWeekFlights({commit, state}, flights) {
        let days = state.weekFares;
        if (flights.departure_flight) {
            for (let d in flights.departure_flight) {
                for (let f in flights.departure_flight[d]) {
                    let lowerFare = flights.departure_flight[d][f].Fares[0];
                    if (!days.departure_flight[d] || days.departure_flight[d]>lowerFare) {
                        days.departure_flight[d] = lowerFare;
                    }
                }
            }
        }
        if (flights.return_flight) {
            for (let d in flights.return_flight) {
                for (let f in flights.return_flight[d]) {
                    let lowerFare = flights.return_flight[d][f].Fares[0];
                    if (!days.return_flight[d] || days.return_flight[d]>lowerFare) {
                        days.return_flight[d] = lowerFare;
                    }
                }
            }
        }
        commit('weekFares', Object.assign({}, days));
    },

    updateBooking({commit, state}, booking) {
      commit("booking", booking);
    },
    paxLastName({commit, state}, {lastName, dni}) {
      commit("lastName", {lastName, dni});
    },
    setPassengerID({commit, state}, passengerID) {
      commit("setPassengerID", passengerID);
    },
    updateBookingSearch({commit, state}, bookingSearch) {
      commit("bookingSearch", bookingSearch);
    },
    setAirports ({commit}) {
        getStations((result) => {
            commit('setAirports', result);
        });
    }
  }
})

export default store;
