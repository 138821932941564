<template>
  <div>
    <andes-header></andes-header>
    <div class="container">

      <div class="row">
        <div class="col-sm-9">


          <!-- DETALLE DE BUSQUEDA ACTUAL  -->
          <div class="row">
            <div class="col-12">
              <div class="current-srch-container">
                <div class="row d-flex align-items-center text-center text-sm-left">
                  <div class="col-12 col-sm-6 ">
                    <h1>Itinerario</h1>
                  </div>
                  <div class="col-12 col-sm-6">
                    <div class="reservation text-center">Reserva: {{booking.RecordLocator}}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr>

          <div class="row">
            <div class="col-12 itin">

              <div v-show="hasPaymentError" class="mb-3 alert alert-danger" role="alert">
                Su pago fue aceptado pero no impacto en la reserva.
                Por favor comuniquese al 0810-777-2633.
              </div>
              <payment-pending />
              <payment-summary class="mb-3"></payment-summary>
              <flight-summary :flight-type="i" class="mb-3" v-for="(segment,i) of booking.Passengers[0].Segments"
                              :flight="segment" :key="i"></flight-summary>
              <passenger-summary class="mb-3"
                  :flight="booking.Passengers[0].Segments[0]"
              ></passenger-summary>
              <!--contact-summary></contact-summary-->
            </div>
          </div>

          <!--div class="row">
            <div class="col-12">
              <div class="current-srch-container">
                <div class="current-srch-data">
                  <div class="current-srch-ond">
                    <h1>Extras</h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr>

          <div class="row">
            <div class="col-12 itin">
              <bag-summary :flight-type="i" class="mb-3" v-for="(segment,i) of booking.Passengers[0].Segments"
                           :flight="segment" :key="i"></bag-summary>
              <assistance-summary class="mb-3" v-if="booking.Passengers[0].Segments.length>0"></assistance-summary>
              <andesclub-summary class="mb-3" v-if="booking.Passengers[0].Segments.length>0"></andesclub-summary>
              <ssr-summary class="mb-3" v-if="booking.Passengers[0].Segments.length>0" title="EMBARQUE PREFERENTE"
                           :fees="['PREF', 'PRF0']" icon="plane"></ssr-summary>
              <ssr-summary class="mb-3" v-if="booking.Passengers[0].Segments.length>0" title="CHECKIN EN AEROPUERTO"
                           :fees="['CHKNAP']" icon="plane"></ssr-summary>
              <extrafee-summary class="mb-3" title="PROPINA" textFalse="Sin propina" image="todos_pueden_volar.jpg"
                                :fees="['PW1', 'PW2', 'PW3', 'PW4']"></extrafee-summary>
              <extrafee-summary class="mb-3" title="DONACIÓN PARA FUNDACIÓN TECHO" textFalse="Sin donación"
                                image="logo-techo-web.jpg"
                                :fees="['TH1', 'TH2', 'TH3', 'TH4', 'TH5']"></extrafee-summary>
            </div>
          </div-->

        </div>
        <!-- SIDEBAR  -->
        <div class="col-sm-3">
          <div class="row">
            <div class="col-12">
              <div class="manage-my-booking-container">
                <p class="checkin_message"><strong>Checkin Online se habilitará desde las 24 hs andes de la salida
                de su vuelo.</strong></p>
              </div>

              <div class="manage-my-booking-container">
                <p class="checkin_message"><strong>IMPORTANTE:</strong>
                  Por favor tenga a bien despachar en el mostrador de Andes cualquier equipaje que supere los 5 kg. El
                  no hacerlo puede devenir en demoras y hasta pérdida del vuelo por parte del pasajero.
                </p>
                <p class="checkin_message">
                  Las mujeres embarazadas podrán viajar sin restricción hasta la semana 20 de embarazo (5 meses).
                  A partir de la semana 21 (5 meses) necesitan un certificado  médico MEDIF emitido dentro de los 5 (cinco) días previos a la fecha de vuelo.
                  Pasajeras con un embarazo igual o superior a las 32 semanas cumplidas (ocho meses) no podrán viajar.
                </p>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
    <andes-footer/>
    <loading-modal v-if="loading"></loading-modal>
  </div>

</template>

<script>
  import {mapState} from 'vuex';
  import SummaryForm from "../components/SummaryForm";
  import Breadcrumb from "../components/Breadcrumb";
  import AndesHeader from "../components/AndesHeader";
  import LoadingModal from "../components/LoadingModal"
  import PaymentSummary from "../components/itinerary/PaymentSummary";
  import FlightSummary from "../components/itinerary/FlightSummary";
  import PassengerSummary from "../components/itinerary/PassengersSummary";
  import ContactSummary from "../components/itinerary/ContactSummary";
  import AndesFooter from "../components/AndesFooter";
  import BagSummary from "../components/itinerary/BagSummary";
  import AssistanceSummary from "../components/itinerary/AssistanceSummary";
  import AndesclubSummary from "../components/itinerary/AndesclubSummary";
  import SsrSummary from "../components/itinerary/SsrSummary";
  import ExtrafeeSummary from "../components/itinerary/ExtrafeeSummary";
  import PaymentPending from "../components/itinerary/PaymentPending";

  export default {
    components: {
      PaymentPending,
      AndesFooter,
      ContactSummary,
      PassengerSummary,
      FlightSummary,
      PaymentSummary,
      LoadingModal,
      AndesHeader,
      Breadcrumb,
      SummaryForm,
      BagSummary,
      AssistanceSummary,
      AndesclubSummary,
      SsrSummary,
      ExtrafeeSummary
    },
    name: 'itinerary-page',
    computed: {
      ...mapState(['booking', 'querySearch', 'loading', 'lastName']),
      hasPaymentError: function () {
        if (!_.isArray(this.booking.Payments)) {
          return false;
        }
        for (var p of this.booking.Payments) {
          if (p.AuthorizationStatus != 4 && !_.isEmpty(p.MercadoPagoPayment) && p.MercadoPagoPayment.Status == 'approved') {
            return true;
          }
        }
        return false;
      }
    },
    methods: {
      getWrongPayment: function () {
        if (!_.isArray(this.booking.Payments)) {
          return null;
        }
        for (var p of this.booking.Payments) {
          if (p.AuthorizationStatus != 4 && !_.isEmpty(p.MercadoPagoPayment) && p.MercadoPagoPayment.Status == 'approved') {
            return p;
          }
        }
        return null;
      }
    }
    ,
    mounted() {
      if (_.isEmpty(this.booking)) {
        this.$router.replace('/');
      }
    }
  }
</script>

