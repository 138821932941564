<template>
  <div class="itin-flight">
    <div class="itin-subheader">
      <h4 class="pull-left">
        <font-awesome-icon icon="suitcase"/>
        {{title}}
      </h4>
    </div>
    <div class="itin-passengers-content bag-flex group row my-1">
      <div class="col-12 col-sm-3">
      </div>
      <div class="col-4 col-sm-3">
        <strong>Equipaje Registrado</strong>
      </div>
      <div class="col-4 col-sm-3">
        <strong>Agrandar tu valija</strong>
      </div>
      <div class="col-4 col-sm-3">
        <strong>Equipaje de mano registrado</strong>
      </div>
    </div>
    <div class="itin-passengers-content bag-flex group row my-1 text-left" v-for="(pax, index) in paxs">
      <div class="col-12 col-sm-3">
        <p>{{pax.FirstName}} {{pax.MiddleName}} {{pax.LastName}}</p>
      </div>
      <div class="col-4 col-sm-3">
        <p class="m-0">{{getRegisterBag(pax, bagFees, 'Sin Equipaje Registrado')}}</p>
      </div>
      <div class="col-4 col-sm-3">
        <p class="m-0">{{getRegisterBag(pax, bagExtra, 'Sin Agrandar')}}</p>
      </div>
      <div class="col-4 col-sm-3">
        <p class="m-0">{{getRegisterBag(pax, caonFees, 'Sin Equipaje de Mano')}}</p>
      </div>
    </div>
  </div>
</template>

<script>
  import {mapState} from 'vuex';
  import moment from 'moment';
  import { normalizeText  } from '@/utils/tools';

  export default {
    name: 'bag-summary',
    props: ['flightType', 'flight'],
    data: function () {
      return {
        bagFees: ['BG1', 'B2', 'B3', 'B4.1', 'B5'],
        caonFees: ['CO1', 'CO2'],
        bagExtra: ['UB1', 'UB2', 'UB3', 'UB4', 'BGEX'],
        paxs: [],
      }
    },
    computed: {
      ...mapState(['booking', 'lastName', 'dni']),
      title: function () {
        if (this.flightType == 0) {
          return "EQUIPAJE DE SALIDA";
        } else {
          return "EQUIPAJE DE REGRESO";
        }
      },
    },
    mounted() {
        let r = [];
        for (let pax of this.booking.Passengers) {
            if (this.lastName && this.tlc(normalizeText(this.lastName)) != this.tlc(normalizeText(pax.LastName))) continue;
            if (this.dni && this.tlc(this.dni) != this.tlc(pax.DocNumber)) continue;
            r.push(pax);
        }
        this.paxs = r;
    },
    methods: {
      tlc (t) {
          return t.replace(/ /g, '').toLowerCase();
      },
      getRegisterBag: function (pax, feeList, textFalse) {
        let segment = _.find(pax.Segments, (s) => s.DepartureDate == this.flight.DepartureDate
          && s.DepartureStation == this.flight.DepartureStation && s.ArrivalStation == this.flight.ArrivalStation);
        if (segment && segment.Legs.length > 0) {
          for (let ssr of segment.Legs[0].SSRs) {
            if (feeList.indexOf(ssr.FeeCode) > -1) {
              return ssr.SSR.Name;
            }
          }
        }
        if (segment && segment.Fees.length > 0) {
          for (let fee of segment.Fees) {
            if (feeList.indexOf(fee.FeeCode) > -1) {
              if (fee.Fee && fee.Fee.Description) {
                return fee.Fee.Description;
              }
            }
          }
        }


        return textFalse;
      }
    }
  }
</script>

