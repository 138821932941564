<template>
  <div>
    <andes-header></andes-header>
    <breadcrumb activePage="0"></breadcrumb>
    <div class="container">
      <div class="row">
        <div class="col-sm-9">
          <search-detail></search-detail>
          <hr/>
          <flight-selector title="VUELO DE IDA" :flights="departureFlights" :is-departure="true"></flight-selector>
          <flight-selector title="VUELO DE VUELTA" :flights="returnFlights" v-if="querySearch.ReturnDate" :is-departure="false"></flight-selector>
        </div>
        <div class="col-sm-3">
          <summary-form :onContinue="onContinue"></summary-form>
        </div>
      </div>
    </div>
    <loading-modal v-if="loading"></loading-modal>
    <andes-footer></andes-footer>
  </div>
</template>

<script>
  import { createBooking } from '@/api/api';
  import Breadcrumb from '../components/Breadcrumb';
  import FlightSelector from "../components/flight/FlightSelector";
  import SearchDetail from "../components/flight/SearchDetail";
  import SummaryForm from '../components/SummaryForm'
  import { mapState } from 'vuex';
  import AndesHeader from "../components/AndesHeader";
  import AndesFooter from "../components/AndesFooter";
  import LoadingModal from "../components/LoadingModal";
export default {
  name: 'flightPage',
  components: {
    LoadingModal,
    AndesHeader,
    Breadcrumb,
    FlightSelector,
    SummaryForm,
    SearchDetail,
    AndesFooter,
    },
  computed: {
    ...mapState(['flights','departureFare', 'returnFare', 'querySearch', 'loading']),
    departureFlights: function () {
      if ('departure_flight' in this.flights) {
        var list = [];
        for (var f in this.flights.departure_flight) {
          list.push({...this.flights.departure_flight[f], FlightNumber:f});
        }
        return list;
      }
      return [];
    },
    returnFlights: function () {
      if ('return_flight' in this.flights) {
        var list = [];
        for (var f in this.flights.return_flight) {
          list.push({...this.flights.return_flight[f], FlightNumber:f});
        }
        return list;
      }
      return [];
    },
  },
  methods: {
    onContinue: function () {
      this.$store.commit('loading', true);
      if (this.returnFare != null){
        createBooking(this.departureFare.SalesKey, this.returnFare.SalesKey, (result) => {
          this.$store.dispatch('updateBooking', result);
          this.$router.replace('passengers');
          this.$store.commit('loading', false);
        });
      } else {
        createBooking(this.departureFare.SalesKey, null, (result) => {
            this.$store.dispatch('updateBooking', result);
            this.$router.replace('passengers');
            this.$store.commit('loading', false);
        });
      }

      /*gtag('event', 'select', {
        'event_category':"flight",
        'event_label': this.querySearch.DepartureStation + '-' + this.querySearch.ArrivalStation
      });*/
    }
  },
  mounted() {
    if (_.isEmpty(this.flights)){
      this.$router.replace('/');
    }
    this.$store.commit('booking', {});
    this.$store.commit('installmentSelected', {});
  }
}
</script>

