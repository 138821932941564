<template>
  <div>
    <andes-header></andes-header>
    <loading-modal></loading-modal>
  </div>
</template>

<script>
  import { getBooking } from '@/api/api';
  import LoadingModal from "../components/LoadingModal";
  import AndesHeader from "../components/AndesHeader";
  export default {
    components: {
      AndesHeader,
      LoadingModal},
    name: 'booking-autosearch',
    computed: {
    },
    mounted: function () {
      if (this.$route.query.rl != undefined && this.$route.query.ln != undefined) {
        this.$store.commit('loading', true);
        getBooking(this.$route.query, (result)=>{

          if (result.error) {
            this.$store.dispatch('updateBookingSearch', {error:result.error, recordLocator:this.$route.query.rl, lastName:this.$route.query.ln});
            this.$router.push('/');
          } else {
            this.$store.dispatch('updateBooking', result);
            this.$router.push('itinerary');
          }
          this.$store.commit('loading', false);



        });
      }

    }
  }
</script>

