<template>
  <div class="container payment-page">
    <form ref="form" id="pay">
      <transition name="fade">
        <div class="form-row" v-if="this.errors.noPayment" transition="fade">
          <div class="form-group col">
            <div class="alert alert-danger" role="alert">
              {{errors.noPayment}}
            </div>
          </div>
        </div>
      </transition>

        <label>Elija medio de pago</label>
      <div class="form-row">
<!--        <p v-for="p in cashMethods">{{p}}</p>-->
        <div class="col" v-for="p in cashMethods" >
          <input type="radio" id="p.id" :value="p.id" v-model="method">
          <img for="p.id" class="ml-3" :src="p.secure_thumbnail"/>
        </div>
      </div>
      <div class="form-row mt-4">
        <div class="form-group col">
          <label>Dirección de e-mail</label>
          <input type="email" id="email" v-model="email" :class="errors.email?'form-control form-error': 'form-control'"
            />
          <br/>
          <p>Recibirá el cupon de pago con instrucciones en su casilla de correo.</p>
          <p>En el caso de solicitarse la devolución, el monto abonado se reintegrará en la cuenta de Mercado Pago del comprador.
          En caso que no tener una cuenta, Mercado Pago creará una utilizando el e-mail que utilizó para realizar la compra.</p>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
  import { makeCashPayment, getBooking } from '@/api/api';
  import { mapState } from 'vuex';
  export default {
    name: "mp-cash-form",
    data: function () {
      return {
        email: "",
        errors: {
          noPayment: "",
          email: ""
        },
        method: null,
        paymentMethods: []
      }
    }, computed: {
      ...mapState(['booking', 'loading', 'checkboxTerms']),
      cashMethods: function () {
        let results = [];
        for (var p of this.paymentMethods) {
          if (p.status == 'active' && p.payment_type_id == 'ticket') {
            results.push(p);
          }
        }
        return results;
      }
    }, methods: {
      isValid: function () {
        var emailPattern = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        this.errors.email = !emailPattern.test(this.email);

        var isValid = true;
        isValid = isValid && !this.errors.email;


        if (this.method == null) {
          isValid = false;
          this.errors.noPayment = "Elija medio de pago por favor";
        } else {
          var paymentMethod = _.find(this.paymentMethods, (p) => p.id == this.method);
          if (paymentMethod.max_allowed_amount < parseFloat(this.booking.balanceDue)) {
            isValid = false;
            this.errors.noPayment = paymentMethod.name + " permite pagos de hasta ARS " + paymentMethod.max_allowed_amount;
          }
        }

        return isValid;
      },
      callServer: function() {
              let paymentAmount = parseInt(this.booking.BalanceDue);
        makeCashPayment(
          this.booking.RecordLocator,
          this.booking.BookingID,
          this.method,
          this.email,
          this.booking.BalanceDue,
          this.booking.BalanceDue,
          (result) => {
            //console.log(result);
            if (result.result == 'approved') {
              this.$store.commit('booking', result.booking);
              this.$router.replace('itinerary');

              /*gtag('event', 'pay_pending', {
                'event_category':"payment",
                'value': paymentAmount
              });*/
            } else if (result.result == 'rejected') {
              this.errors.noPayment = "Rechazado: " + " " + result.detail ;
              this.$store.commit('booking', result.booking);
              Mercadopago.clearSession();
              /*gtag('event', 'pay_rejected', {
                'event_category':"payment",
                'event_label': result.detail
              });*/
            } else {
              // error: busco el booking actualizado para verificar si impacto o no el pago.
              let params = {
                rl: this.booking.RecordLocator,
                ln: this.booking.Passengers[0].LastName
              };
              getBooking(params, (result) => {
                this.$store.dispatch('updateBooking', result);
                for (var p of result.Payments) {
                  if (p.AuthorizationStatus != 4 && !_.isEmpty(p.MercadoPagoPayment) && p.MercadoPagoPayment.type == 'ticket' && p.MercadoPagoPayment.Status == 'pending') {
                    this.$router.replace('itinerary');
                  }
                }
              });

              this.errors.noPayment = result.message ;
              Mercadopago.clearSession();

              /*gtag('event', 'pay_failed', {
                'event_category':"payment",
                'event_label': result.message
              });*/
            }
            this.$store.commit('loading', false);
          }
        )
      },
      onMakePayment: function() {
        this.errors.noPayment = "";
        if (this.isValid()){
          this.$store.commit('loading', true);
          this.callServer()
        }
      },
    },
    mounted() {
      Mercadopago.setPublishableKey(process.env.MP_ACCESS_TOKEN);
      Mercadopago.getAllPaymentMethods((status, response) => {
        if (status == 200) {
          this.paymentMethods = response;
        }
      });

      if (this.booking && this.booking.ContactInformation && this.booking.ContactInformation.length) {
        this.email = this.booking.ContactInformation[0].EmailAddress;
      }
    }
  }
</script>
