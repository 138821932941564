// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import 'bootstrap';
//import '@/css/flaticon.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/js/dist/util';
import 'bootstrap-datepicker';
import 'bootstrap-3-typeahead';
import '@/css/app.css';
import '@/css/custom.css';
import '@/css/seatmap.css';
import Vue from 'vue';
import store from './store/store';
import App from './App';
import VueRouter from 'vue-router';
import router from './router';
import $ from 'jquery';
import jQuery from 'jquery';
window.$ = $;
window.jQuery = $;
import VueResource from "vue-resource";
import * as _ from 'lodash';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faImage, faPlus, faMinusCircle, faMinus, faClipboard, faPlusCircle, faFolderOpen, faSave, faWindowClose,
  faTrashAlt, faHistory, faHdd, faPlusSquare, faClipboardList, faClipboardCheck, faDollarSign, faStar, faPen,
  faUser, faEnvelope, faPlane, faPlaneDeparture, faPlaneArrival, faCreditCard, faChevronDown, faBriefcase, faSync,
  faClock, faSuitcase, faShieldAlt, faCalendarCheck}
    from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
library.add(faImage, faPlus, faMinusCircle, faMinus, faClipboard, faPlusCircle, faFolderOpen, faSave, faWindowClose,
  faTrashAlt, faHistory, faHdd, faPlusSquare, faClipboardList, faClipboardCheck, faDollarSign, faStar, faPen,
  faUser, faEnvelope, faPlane, faPlaneDeparture, faPlaneArrival, faCreditCard, faChevronDown, faBriefcase, faSync,
  faClock, faSuitcase, faShieldAlt, faCalendarCheck)
Vue.component('font-awesome-icon', FontAwesomeIcon);

Vue.config.productionTip = false;
Vue.config.devtools = true;
/* eslint-disable no-new */

new Vue({
  el: '#app',
  store,
  router,
  components: { App },
  template: '<App/>'
})

router.afterEach((to, from) => {

  /*gtag('config', window.GA_TRACKING_ID, {
    cookie_prefix: 'pss',
    cookie_expires: 0,  // 28 days, in seconds
    page_path: to.fullPath,
    app_name: 'Andes Online - Book',
    send_page_view: true,
  });*/

  //gtag('set', 'page', to.path);
  //gtag('send', 'pageview');

});
