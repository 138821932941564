<template>
  <div class="itin-flight">
    <div class="itin-subheader">
      <h4 class="pull-left">
        <font-awesome-icon icon="shield-alt"/>
        ASISTENCIA AL VIAJERO
      </h4>
    </div>
    <div class="itin-passengers-content bag-flex group row my-1" >
      <div class="col-6 col-sm-4 my-auto">
      </div>
      <div class="col-6 offset-sm-2 my-auto">
        <strong>Asistencia contratada</strong>
      </div>
    </div>
    <div class="itin-passengers-content bag-flex group row my-1" v-for="(pax, index) in booking.Passengers">
      <div class="col-6 col-sm-4 my-auto text-left">
        <p>{{pax.FirstName}} {{pax.MiddleName}} {{pax.LastName}}</p>
      </div>
      <div class="col-6 offset-sm-2 my-auto">
        <p>{{getRegisterSSR(pax, 'Sin Asistencia')}}</p>
      </div>
    </div>
  </div>
</template>

<script>
  import {mapState} from 'vuex';
  import moment from 'moment';

  export default {
    name: 'assistance-summary',
    data: function () {
      return {
        fees: ['SB1A', 'SB1B', 'SB1C', 'SB1D', 'SB1C', 'SB1E', 'SB2A', 'SB2B', 'SB2C', 'SB2E', 'SB3A', 'SB3B', 'SB3C', 'SB3D', 'SB3E',
          'SP1A', 'SP1B', 'SP1C', 'SP1D', 'SP1C', 'SP1E', 'SP2A', 'SP2B', 'SP2C', 'SP2E', 'SP3A', 'SP3B', 'SP3C', 'SP3D', 'SP3E'],
      }
    },
    computed: {
      ...mapState(['booking']),
    },
    methods: {
      getRegisterSSR: function (pax, textFalse) {
        let segment = pax.Segments[0];
        if (segment && segment.Legs.length > 0) {
          for (let ssr of segment.Legs[0].SSRs) {
            if (this.fees.indexOf(ssr.FeeCode) > -1) {
              return ssr.SSR.Name;
            }
          }
        }
        return textFalse;
      }
    }
  }
</script>

