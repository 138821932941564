<template>
  <div>
    <label>{{label}}</label>
    <div class="row">
      <div class="col-md-3 pr-md-2">
        <select type="date" class="form-control" id="day" @change="this.onDateSelectorChange" v-model="birthDayValue"
                :class="error || errors.birthDay?'form-control form-error': 'form-control'">
          <option value="0" :selected="birthDayValue == 0">Día</option>
          <option :value="index" v-for="index in 31" :key="index" :selected="birthDayValue == index">{{index}}</option>
        </select>
      </div>
      <div class="col-md-5 p-md-0 py-2 py-md-0">
        <select type="date" class="form-control" id="month" @change="this.onDateSelectorChange" v-model="birthMonthValue"
          :class="error || errors.birthMonth?'form-control form-error': 'form-control'">
          <option value="0" :selected="birthMonthValue == 0">Mes</option>
          <option value="1" :selected="birthMonthValue == 1">Enero</option>
          <option value="2" :selected="birthMonthValue == 2">Febrero</option>
          <option value="3" :selected="birthMonthValue == 3">Marzo</option>
          <option value="4" :selected="birthMonthValue == 4">Abril</option>
          <option value="5" :selected="birthMonthValue == 5">Mayo</option>
          <option value="6" :selected="birthMonthValue == 6">Junio</option>
          <option value="7" :selected="birthMonthValue == 7">Julio</option>
          <option value="8" :selected="birthMonthValue == 8">Agosto</option>
          <option value="9" :selected="birthMonthValue == 9">Septiembre</option>
          <option value="10" :selected="birthMonthValue == 10">Octubre</option>
          <option value="11" :selected="birthMonthValue == 11">Noviembre</option>
          <option value="12" :selected="birthMonthValue == 12">Diciembre</option>
        </select>
      </div>
      <div class="col-md-4 pl-md-2">
        <select class="form-control" id="year" @change="this.onDateSelectorChange" v-model="birthYearValue"
        :class="error || errors.birthYear?'form-control form-error': 'form-control'">
          <option value="0" :selected="birthYearValue == 0">Año</option>
          <option :value="year" v-for="year in yearRange" :key="year" :selected="birthYearValue == year">{{year}}</option>
        </select>
      </div>
    </div>
  </div>
</template>

<script>
  import { getAvailability } from '@/api/api';
  import moment from 'moment';
  export default {
    name: 'date-selector',
    props: ['date', 'label', 'minYear', 'maxYear', 'error'],
    data: function () {
      return  {
        birthDayValue: 0,
        birthMonthValue: 0,
        birthYearValue: 0,
        errors: {
          birthDay: false,
          birthMonth: false,
          birthYear: false,
        }
      }
    },
    computed: {
      yearRange: function() {

        var list = [];

        if (this.minYear != undefined && this.maxYear != undefined) {
          for (var i=this.minYear; i <= this.maxYear; i++) {
            list.push(i)
          }
        } else {
          var currentYear = moment().year()
          for (var i=1916; i <= currentYear; i++) {
            list.push(i)
          }
        }
        return list.reverse();
      },
    },
    methods: {
      onDateSelectorChange: function () {
        if (this.birthDayValue > 0
          && this.birthMonthValue > 0
          && this.birthYearValue > 0) {
          this.$emit('update:date', moment({
            y: this.birthYearValue,
            M: this.birthMonthValue - 1,
            d: this.birthDayValue
          }).format("YYYY-MM-DD"));
        }
      },
      validate: function() {
        this.errors.birthDay = this.birthDayValue == 0;
        this.errors.birthMonth = this.birthMonthValue == 0;
        this.errors.birthYear = this.birthYearValue == 0;

        var isValid = true;

        isValid = isValid && !this.errors.birthDay;
        isValid = isValid && !this.errors.birthMonth;
        isValid = isValid && !this.errors.birthYear;

        return isValid;
      },
    },
    watch: {
      date: function () {
        if (this.date) {
          var d = moment(this.date, "YYYY-MM-DD")
          this.birthDayValue = d.date()
          this.birthMonthValue = d.month() + 1
          this.birthYearValue = d.year()
        }
      }
    },
    mounted: function () {
      if (this.date) {
        var d = moment(this.date, "YYYY-MM-DD")
        this.birthDayValue = d.date()
        this.birthMonthValue = d.month() + 1
        this.birthYearValue = d.year()
      }
    }

  }
</script>

