<template>
  <div class="sticky-top pt-2">
    <div class="row">
      <div class="price-display-header col-12">
        <h4>Tu vuelo</h4>
        <font-awesome-icon icon="chevron-down" class="pull-right"/>
      </div>
    </div>
    <div class="row">
      <div class="price-display col-12" v-if="departureSelected">
        <div>
          <div class="widget-flight-data">
            <p class="widget-flight-data-departure">Salida</p>
            <p class="widget-flight-data-date">{{getDate(departureSelected.FlightDate)}}</p>
          </div>

          <div class="widget-airport-name">
            <ul>
              <li>{{departureSelected.DepartureStation}}</li>
              <li>{{departureSelected.ArrivalStation}}</li>
            </ul>
          </div>

          <div class="widget-flight-departure-date">
            <p>{{departureSelected.STDTime}}hs</p>
            <p>{{departureSelected.STATime}}hs</p>
          </div>
        </div>

        <div v-if="returnSelected">
          <div class="widget-flight-data">
            <p class="widget-flight-data-departure">Regreso</p>
            <p class="widget-flight-data-date">{{getDate(returnSelected.FlightDate)}}</p>
          </div>

          <div class="widget-airport-name">
            <ul>
              <li>{{returnSelected.DepartureStation}}</li>
              <li>{{returnSelected.ArrivalStation}}</li>
            </ul>
          </div>

          <div class="widget-flight-departure-date">
            <p>{{returnSelected.STDTime}}hs</p>
            <p>{{returnSelected.STATime}}hs</p>
          </div>
        </div>


        <div class="widget-price-display">
          <div> Tarifa:</div>
          <div class="pull-right">{{departureFare.Currency}} {{this.toCurrencyFormat(price)}}</div>
        </div>

        <div class="widget-price-display">
          <div>Impuestos:</div>
          <div class="pull-right">{{departureFare.Currency}} {{this.toCurrencyFormat(taxes)}}</div>
        </div>

        <div class="widget-price-display" v-if="interes">
          <div>Interés:</div>
          <div class="pull-right">{{departureFare.Currency}} {{this.toCurrencyFormat(interes)}}</div>
        </div>

        <div class="widget-price-display">
          <div>Costo de emisión:</div>
          <div class="pull-right">{{departureFare.Currency}} 0,00</div>
        </div>

        <div class="widget-price-display widget-total">
          <div>Total:</div>
          <div class="pull-right">{{departureFare.Currency}} {{this.toCurrencyFormat(total)}}</div>
        </div>

        <div class="terms_checkbox" v-if="terms">
          <p><input type="checkbox" id="checkbox_terms" v-model="checkboxConditions"> Acepto <a
            href="http://www.andesonline.com/terminos-condiciones/" target="_blank">términos y condiciones</a></p>
          <p id="error-terms-message" v-if="termsError" style="font-size: 11px; color: #fff">Debe aceptar los términos y
            condiciones</p>
        </div>

        <div class="widget-button-continue">
          <button class="btn" type="button" @click="onContinue" :disabled="!flightsSelected">Continuar</button>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
  import {mapState} from 'vuex';
  import moment from 'moment';
  import {formatMoney} from "../utils/utils";

  export default {
    name: 'summary-form',
    props: ['onContinue', 'terms'],
    data: function () {
      return {
        checkboxConditions: null,
      }
    },
    watch: {
      checkboxConditions: function () {
        this.$store.commit('checkboxTerms', this.checkboxConditions);
      }
    },
    computed: {
      ...mapState(['booking', 'departureSelected', 'returnSelected', 'departureFare', 'returnFare', 'adultCount', 'childCount', 'infantCount', 'installmentSelected', 'querySearch', 'termsError']),
      price: function () {
        var value = 0;
        if (this.returnFare != null) {
          value = parseFloat(this.departureFare.Amount) + parseFloat(this.returnFare.Amount);
        } else {
          value = parseFloat(this.departureFare.Amount);
        }
        return value * this.ticketCount;
      },
      taxes: function () {
        var value = 0;
        if (this.returnFare != null) {
          value = parseFloat(this.departureFare.Taxes.Total) + parseFloat(this.returnFare.Taxes.Total) + parseFloat(this.departureFare.Charges.Total) + parseFloat(this.returnFare.Charges.Total);
        } else {
          value = parseFloat(this.departureFare.Taxes.Total) + parseFloat(this.departureFare.Charges.Total);
        }
        value = value * this.ticketCount;
        return value.toFixed(2);
      },
      total: function () {
        if (this.installmentSelected.installments && this.installmentSelected.installments > 1) {
          return this.installmentSelected.total_amount;
        }

        if (this.booking && this.booking.TotalCost) {
          return parseFloat(this.booking.TotalCost).toFixed(2)
        }

        var value = parseFloat(this.price) + parseFloat(this.taxes);
        return value.toFixed(2)
      },
      ticketCount: function () {
        return this.adultCount + this.childCount;
      },
      interes: function () {
        if (this.installmentSelected.installments && this.installmentSelected.installments > 1) {
          return (this.installmentSelected.total_amount - parseFloat(this.booking.TotalCost)).toFixed(2);
        }
      },
      flightsSelected: function () {
        if (this.querySearch.ReturnDate && !this.returnSelected) return false;
        if (!this.departureSelected) return false;
        return true;
      }
    },
    methods: {
      getDate: function (d) {
        return moment(d).locale('es').format('DD MMMM YYYY');
      },
      toCurrencyFormat: function (amount) {
        return formatMoney(amount);
      }
    }

  }
</script>