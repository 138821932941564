import Vue from 'vue';
import Router from 'vue-router';
import store from '@/store/store';
import FlightPage from '@/pages/FlightPage';
import AutomaticSearchPage from '@/pages/AutomaticSearchPage';
import CheckinSearchPage from '@/pages/CheckinSearchPage';
import BookingSearchPage from '@/pages/BookingSearchPage';
import PassengersPage from '@/pages/PassengersPage';
import PaymentPage from '@/pages/PaymentPage';
import ItineraryPage from '@/pages/ItineraryPage';
import CheckinPage from '@/pages/CheckinPage';
import CheckinReviewPage from '@/pages/CheckinReviewPage.vue';
import SeatMapPage from "@/pages/SeatMapPage";
import BoardingPassPage from "@/pages/BoardingPassPage";
import PassengerUncompletedPage from "@/pages/PassengersUncompletedPage";
import PendingPaymentPage from '@/pages/PendingPaymentPage';
import ExtrasPage from '@/pages/ExtrasPage';

Vue.use(Router)

const router = new Router({
  routes: [
    { path: '*', redirect: '/'},
//    { path: '/', component: AutomaticSearchPage, props: true},
    { path: '/', component: CheckinSearchPage, props: true},
    { path: '/checkinsearch', component: CheckinSearchPage, props: true},
    { path: '/search', component: AutomaticSearchPage, props: true},
    { path: '/flight', component: FlightPage, props: true},
    { path: '/extras', component: ExtrasPage, props: true},
    { path: '/passengers', component: PassengersPage, props: true},
    { path: '/payment', component: PaymentPage, props: true},
    { path: '/itinerary', component: ItineraryPage, props: true},
    { path: '/BookingSearch', component: BookingSearchPage, props: true},
    { path: '/checkin', component: CheckinPage, props: true},
    { path: '/checkinreview', component: CheckinReviewPage, props: true},
    { path: '/seatmap', component: SeatMapPage, props: true},
    { path: '/boarding', component: BoardingPassPage, props: true},
    { path: '/passenger-uncompleted', component: PassengerUncompletedPage, props: true},
    { path: '/payment-pending', component: PendingPaymentPage, props: true},
  ]
})

export default router;

