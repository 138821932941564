<template>
  <div>
    <andes-header></andes-header>
    <breadcrumb activePage="3"></breadcrumb>
    <div class="container payment-page">
      <div class="row">
        <div class="col-sm-9">
          <div class="row d-flex align-items-center text-center text-sm-left">
            <div class="col-12 col-sm-6 ">
              <h3 class="m-0">REALIZAR PAGO</h3>
            </div>
            <div class="col-12 col-sm-6">
              <div class="reservation text-center" v-if="booking.Payments.length">Reserva: {{booking.RecordLocator}}</div>
            </div>
          </div>
          <hr/>
          <div class="itin">
            <payment-summary class="mb-3" v-if="booking.Payments.length"></payment-summary>
          </div>

          <div class="row mt-4 ml-3 mr-3">
             <div class="col-md-6 col-xs-12">
                <div class="row">
                  <input type="radio" id="credit" value="card" v-model="picked">
                  <label for="credit" class="ml-3">Tarjetas Crédito/Débito</label>
                </div>
                <div class="row" v-if="getAP">
                  <input type="radio" id="cash" value="cash" v-model="picked">
                  <label for="cash" class="ml-3">Efectivo</label>
                </div>
            </div>
             <div class="col-md-6 col-xs-12">
                <div class="row">
                  <img src="@/img/mercadopago.png"/>
                </div>
             </div>
          </div>
          <!--div class="row mt-4 ml-3 mr-3">

            <div class="col">
              <img src="https://imgmp.mlstatic.com/org-img/banners/ar/medios/785X40.jpg" title="MercadoPago - Medios de pago" alt="MercadoPago - Medios de pago" width="785" height="40"/>
            </div>
          </div-->


          <div class="card shadow mt-3" v-if="booking.BalanceDue > 0">
            <div class="card-body">
              <h3 class="total-title">TOTAL A PAGAR:  <span>ARS {{toCurrencyFormat(booking.BalanceDue)}}</span></h3>
              <hr/>
              <mp-form ref="mp" v-if="picked == 'card'"></mp-form>
              <mp-cash-form ref="mpCash" v-else></mp-cash-form>
            </div>
          </div>
        </div>
        <div class="col-sm-3">
          <summary-form :onContinue="onContinue" :terms="true"></summary-form>
        </div>
      </div>
    </div>
    <loading-modal v-if="loading"></loading-modal>
    <session-timeout-modal></session-timeout-modal>
  </div>
</template>

<script>
  import SummaryForm from "../components/SummaryForm";
  import Breadcrumb from "../components/Breadcrumb";
  import { mapState } from 'vuex';
  import MpForm from "../components/payment/MercadoPagoForm";
  import MpCashForm from "../components/payment/MercadoPagoCashForm";
  import AndesHeader from "../components/AndesHeader";
  import LoadingModal from "../components/LoadingModal";
  import PaymentSummary from "../components/itinerary/PaymentSummary";
  import {formatMoney} from "../utils/utils";
  import SessionTimeoutModal from "../components/SessionTimeout";
  var moment = require('moment');

  export default {
    components: {
      SessionTimeoutModal,
      PaymentSummary,
      LoadingModal,
      AndesHeader,
      MpForm,
      MpCashForm,
      Breadcrumb,
      SummaryForm},
    name: 'payment-page',
    computed: {
      ...mapState(['booking', 'loading', 'checkboxTerms']),
      getAP: function() {
        if (this.booking.Passengers[0] && this.booking.Passengers[0].Segments[0]
            && this.booking.Passengers[0].Segments[0].Legs && this.booking.Passengers[0].Segments[0].Legs[0]
            && this.booking.Passengers[0].Segments[0].Legs[0].InventoryLeg ) {
          let departureDate = moment(this.booking.Passengers[0].Segments[0].Legs[0].InventoryLeg.STD);
          let today = moment(new Date());
          let diff =  moment.duration(departureDate.diff(today));
          if (diff.days()>=1){
            return true;
          }
        }
      }

    },
    data: function() {
      return {
        picked: "card"
      }
    },
    methods: {
     onContinue: function () {

       if (!this.checkboxTerms) {
        this.$store.commit('termsError', true);
        return;
       }

       if (this.booking.BalanceDue <= 0) {
         this.$router.replace("itinerary");
       } else {
         if (this.picked == 'card') {
           this.$refs.mp.onMakePayment();
         } else {
           this.$refs.mpCash.onMakePayment();
         }
       }

       /*gtag('event', 'pay_attempt', {
         'event_category':"payment"
       });*/

     },
      toCurrencyFormat: function (amount) {
        return formatMoney(amount);
      }
    }, mounted() {
      if (_.isEmpty(this.booking)){
       this.$router.replace('/');
      }
    }
  }
</script>

