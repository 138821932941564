<template>
  <div>
    <andes-header></andes-header>
    <div class="container checkin-page">
      <div class="row">
          <div class="col-12">
            <h3>REVISAR CHECK-IN</h3>
          </div>
      </div>
          <hr/>
        <div class="row">
            <div class="col-12">
                <h5>Por favor asegurese que la información que se encuentra debajo sea correcta antes de realizar el Check-in.</h5>
            </div>
        </div>
        <div class="row">
          <div class="col-12 checkin-flight">
            <span>OY {{checkinFlight.Legs[0].InventoryLeg.FlightNumber}} <b>{{checkinFlight.DepartureStation}}
            <img src="@/img/plane-32.png" alt="Flying to">
            {{checkinFlight.ArrivalStation}}</b> {{getFormatDate}}</span>
          </div>
        </div>
        <div class="row">
          <div class="col-8">
              <div class="card shadow">
                  <div class="card-header">
                    <div class="row" v-for="(pax, i) of booking.Passengers" v-if="getPaxSeat(pax)">
                        <div class="col-8">
                            <h5>{{pax.LastName}}, {{pax.FirstName}} {{pax.MiddleName}}</h5>
                        </div>
                        <div class="col-4 text-right">
                            <h5>{{getPaxSeat(pax)}}</h5>
                        </div>
                    </div>
                  </div>
              </div>
              <div class="d-inline-block w-100 mt-3">
                  <div class="pull-right">
                    <button type="button" class="btn btn-outline-danger" @click="back">Volver</button>
                    <button type="button" class="btn btn-andes" @click="onContinue">Continuar</button>
                  </div>
              </div>

          </div>
        </div>
      <div class="row">
          <div class="col-4">
          </div>
      </div>
    </div>
    <loading-modal v-show="loading"></loading-modal>
    <andes-footer></andes-footer>
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  import { checkin, getBooking } from '@/api/api';
  import AndesHeader from "../components/AndesHeader";
  import LoadingModal from "../components/LoadingModal";
  import AndesFooter from "../components/AndesFooter";
  import moment from 'moment';
  export default {
    components: {
      LoadingModal,
      AndesHeader,
      AndesFooter,
      },
    name: 'checkin-review-page',
    computed: {
      ...mapState(['booking', 'loading', 'checkinFlight', 'segmentsToCheck']),
      getFormatDate: function() {
        return moment(this.checkinFlight.DepartureDate).locale('es').format('DD MMMM YYYY')
      }
    },
    methods: {
        isChecked: function(pax) {

        },
        getPaxSeat: function(pax) {

          for (var segmentId of this.segmentsToCheck) {
            for (var seg of pax.Segments) {
              if (seg.SegmentID == segmentId) {
                return seg.Legs[0].UnitDesignator
              }
            }
          }
          return '';
        },
        back: function() {
            this.$router.replace("seatmap");
        },
        onContinue: function() {
          // checkin every pax and go to itinerary
            // this.$router.replace("itinerary");
          let promises = []
          for (var segmentId of this.segmentsToCheck) {

            for (var pax of this.booking.Passengers) {
              for (var seg of pax.Segments) {

                if (seg.SegmentID == segmentId) {
                  this.$store.commit('loading', true);
                  let params = {
                    'RecordLocator': this.booking.RecordLocator,
                    'LegNumber': seg.Legs[0].LegNumber,
                    'UnitDesignator': seg.Legs[0].UnitDesignator,
                    'SegmentID': seg.SegmentID,
                    "InventoryLegID": seg.Legs[0].InventoryLegID,
                    "FlightId": seg.Legs[0].InventoryLeg.FlightId,
                  }

                  promises.push(checkin(params).then(r => r.json()));

                }
              }
            }
          }

          Promise.all(promises)
            .then(results => {
              //console.log(results);

              var queryParams = {
                rl: this.booking.RecordLocator,
                ln: this.booking.Passengers[0].LastName,
              }
              getBooking(queryParams, (result) => {
                this.$store.dispatch('updateBooking', result);
                this.$router.replace('itinerary');
                this.$store.commit('loading', false);
              });
              /*gtag('event', 'checkin', {
                'event_category': "seatmap",
              });*/
            })
        }
    },
    mounted() {
      if (_.isEmpty(this.booking)) {
        this.$router.replace('/');
      }
    }
  }
</script>

