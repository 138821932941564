<template>

  <div class="tooltip-medium tooltip-class">
    <div class="tooltip-class-title">
      <p class="text-left">
        Tarifa intermedia incluye:
      </p>
    </div>
    <div class="tooltip-class-content text-left">
      <p><span class="fa fa-laptop"></span> Check-in Online</p>
      <p><span class="fa fa-utensils"></span> Bebida</p>
<!--      <p><span class="seat-icon"><img src="@/img/PassengerSeat-32x32.png" style="width:20px"/></span> Descuento en asientos</p>-->
      <p><span class="fa fa-briefcase"></span> 1 valija de 15kg</p>
      <p><span class="fa fa-exclamation-triangle"></span> Cambios con penalidad.</p>
      <p><span class="fa fa-exclamation-triangle"></span><strong> No incluye:</strong> Checkin en aeropuerto.</p>
      <p><span class="fa fa-exclamation-triangle"></span><strong> No permite:</strong> Reembolso.</p>
    </div>
    <hr>
    <div class="tooltip-class-footer text-left">
      <p>Querés despachar dos valijas y y poder cambiar tu pasaje? Elegí tarifa premium.</p>
    </div>
  </div>

</template>

<script>
  export default {
    name: "MediumClassTooltipDetail"
  }
</script>
