<template>
    <div class="row">

      <div class="col-12">
        <div class="avail-header">
          <h4><span class="flaticon-transport"></span> {{title}}</h4>
          <!--button type="button" class="btn"><i class="material-icons">calendar_today</i> Ver el mes completo</button-->
        </div>

        <div class="low-fare-tab-container">
          <ul class="low-fare-tabs">
            <li class="low-fare-arrow low-fare-left-arrow">
              <!--a href="#"><i class="material-icons">arrow_back_ios</i></a-->
            </li>
            <li v-for="d of getWeekDaysDeparture()" :class="isActive(d)">
              <a @click="setSearchDate(d)">
                <div class="fare month">{{getMonth(d)}}</div>
                <div class="low-fare-date">{{getDay(d)}}</div>
                <div class="low-fare-price">{{getWeekFare(d)}}</div>
              </a>
            </li>
            <li class="low-fare-arrow low-fare-right-arrow">
              <!--a href="#"><i class="material-icons">arrow_forward_ios</i></a-->
            </li>
          </ul>
            <table class="table avail-table d-none d-md-block" v-if="flights.length>0">
              <tr>
                <th class="align-bottom">Salida</th>
                <th class="align-bottom">Llegada</th>
                <th class="align-bottom">Duración</th>
                <th class="align-bottom">Vuelo</th>
                <th class="tab-basico">
                  <div class="tag">Basico</div>
                  <class-tooltip-detail></class-tooltip-detail>

                </th>

                <th class="tab-intermedio">
                  <div class="tag">Intermedio <br>
                    <img src="@/img/PassengerSeat-32x32_white.png" style="width:20px"/>
                    <font-awesome-icon icon="plus"/>
                    <font-awesome-icon icon="briefcase" size="1x"/>
                    <medium-class-tooltip-detail></medium-class-tooltip-detail>
                  </div>
                </th>

                <th class="tab-premium">
                  <div class="tag">Premium <br>
                    <img src="@/img/PassengerSeat-32x32_white.png" style="width:20px"/>
                    <font-awesome-icon icon="plus"/>
                    <font-awesome-icon icon="briefcase" size="1x"/>
                    <font-awesome-icon icon="plus"/>
                    <font-awesome-icon icon="sync" size="1x"/>
                    <premium-class-tooltip-detail></premium-class-tooltip-detail>
                  </div>
                </th>
              </tr>
              <tr class="avail-data-row" v-for="f in flights">
                <td><div class="text-center">{{f.STDTime}}</div></td>
                <td><div class="text-center">{{f.STATime}}</div></td>
                <td><div class="text-center">{{escalasTxt(f)}}</div><div class="text-center">{{getDuration(f)}}hs</div></td>
                <td><div class="text-center">{{f.FlightNumber}}</div></td>
                <td class="avail-table-price" v-if="getFare(f, 'A')">
                  <a :class="{'is-checked': isSelected(getFare(f, 'A'))}" @click="select(f, 'A')">{{getFareString(f, "A")}}</a>
                  <p v-if="getLid(f, 'A')<6">{{getLid(f, 'A')}} asientos libres</p>
                  <span class="tarifa-clase">{{getClassOfServiceString(f, "A")}}</span>
                </td>
                <td class="avail-table-price" v-else>-</td>

                <td class="avail-table-price"  v-if="getFare(f, 'B')">
                  <a :class="{'is-checked': isSelected(getFare(f, 'B'))}" @click="select(f, 'B')">{{getFareString(f, "B")}}</a>
                  <p v-if="getLid(f, 'B')<6">{{getLid(f, 'B')}} asientos libres</p>
                  <span class="tarifa-clase">{{getClassOfServiceString(f, "B")}}</span>
                </td>
                <td class="avail-table-price" v-else>-</td>
                <td class="avail-table-price"  v-if="getFare(f, 'M')">
                  <a :class="{'is-checked': isSelected(getFare(f, 'M'))}" @click="select(f, 'M')">{{getFareString(f, "M")}}</a>
                  <p v-if="getLid(f, 'M')<6">{{getLid(f, 'M')}} asientos libres</p>
                  <span class="tarifa-clase">{{getClassOfServiceString(f, "M")}}</span>
                </td>
                <td class="avail-table-price" v-else>-</td>
              </tr>
            </table>

            <table class="flight-info-mobile-table d-block d-md-block d-lg-none" v-if="flights.length>0">
              <tr>
                <th></th>
                <th class="tab-basico">Basico</th>
                <th class="tab-intermedio" >Intermedio</th>
                <th class="tab-premium" >Premium</th>
              </tr>
              <tr class="flight-info-mobile-table-data" v-for="f in flights">
                <td>
                  <p>{{f.STDTime}}</p>
                  <p>{{f.STATime}}</p>
                  <p><strong>Vuelo {{f.FlightNumber}}</strong></p>
                </td>
                <td v-if="getFare(f, 'A')">
                  <p :class="{'is-checked': isSelected(getFare(f, 'A'))}" @click="select(f, 'A')">{{getFareString(f, "A")}}</p>
                  <p v-if="getLid(f, 'A')<6">{{getLid(f, 'A')}} asientos libres</p>
                  <p>{{getClassOfServiceString(f, "A")}}</p>
                </td>
                <td v-else>-</td>

                <td v-if="getFare(f, 'B')">
                  <p :class="{'is-checked': isSelected(getFare(f, 'B'))}" @click="select(f, 'B')">{{getFareString(f, "B")}}</p>
                  <p v-if="getLid(f, 'B')<6">{{getLid(f, 'B')}} asientos libres</p>
                  <p>{{getClassOfServiceString(f, "B")}}</p>
                </td>
                <td v-else>-</td>
                <td v-if="getFare(f, 'M')">
                  <p :class="{'is-checked': isSelected(getFare(f, 'M'))}" @click="select(f, 'M')">{{getFareString(f, "M")}}</p>
                  <p v-if="getLid(f, 'M')<6">{{getLid(f, 'M')}} asientos libres</p>
                  <p>{{getClassOfServiceString(f, "M")}}</p>
                </td>
                <td v-else>-</td>

              </tr>

            </table>

            <div class="avail-info-no-flights" v-else>
              <h3>Este vuelo no tiene asientos disponibles. Te invitamos a buscar en otra fecha.</h3>
            </div>

            <a v-if="flights.length>0" href="https://www.andesonline.com/informacion/condiciones-de-tarifas/" class="fare_link_detail" target="_blank">(*) Ver condiciones de tarifas</a>
        </div>
      </div>
    </div>
</template>

<script>
  import { mapState } from 'vuex';
  import moment from 'moment';
  import { getAvailability, getAvailabilityByWeek  } from '@/api/api';
  import {formatMoney} from "../../utils/utils";
  import ClassTooltipDetail from "./ClassTooltipDetail";
  import MediumClassTooltipDetail from "./MediumClassTooltipDetail";
  import PremiumClassTooltipDetail from "./PremiumClassTooltipDetail";

  export default {
    name: 'flight-selector',
    components: {PremiumClassTooltipDetail, MediumClassTooltipDetail, ClassTooltipDetail},
    props:["title", "flights", "isDeparture"],
    methods: {
      select: function(f, classOfService) {
        // update store model with selected leg
        var fare = this.getFare(f, classOfService);
        if (this.isDeparture) {
          this.$store.commit("departureSelected", f);
          this.$store.commit("departureFare", fare);
        } else {
          this.$store.commit("returnSelected", f);
          this.$store.commit("returnFare", fare);
        }
      },
      getFare: function(flight, clazz) {
        for (var fare of flight.Fares) {
          if (fare.ProductClassId === clazz) {
            return fare;
          }
        }
        return null;
      },
      getLid: function(flight, clazz) {
        for (var fare of flight.Fares) {
          if (fare.ProductClassId === clazz) {
            return fare.Lid;
          }
        }
        return null;
      },

      getFareString: function(flight, clazz) {
        var fare = this.getFare(flight, clazz);
        if (fare != null) {
          return fare.Currency + " " + formatMoney(fare.Amount);
        }
        return "-"
      },
      getClassOfServiceString: function(flight, clazz) {
        var fare = this.getFare(flight, clazz);
        if (fare != null) {
          return "Tarifa clase " + fare.ClassOfService;
        }
        return ""
      },
      getDuration: function(f) {
        if (f.Legs) {
            var departure = moment(f.Legs[0].InventoryLeg.STD);
            var arrival = moment(f.Legs[this.flight.Legs.length - 1].InventoryLeg.STA);
            return moment.utc(arrival.diff(departure)).format("HH:mm");
        } else if (f.STA && f.STD) {
            var departure = moment(f.STD);
            var arrival = moment(f.STA);
            return moment.utc(arrival.diff(departure)).format("HH:mm");
        }
      },
      isSelected: function(f) {
        if (f == null) {
          return f;
        }
        if (this.isDeparture) {
          return this.departureFare && this.departureFare.SalesKey === f.SalesKey;
        } else {
          return this.returnFare && this.returnFare.SalesKey === f.SalesKey;
        }
      },
      getWeekDaysDeparture: function() {
        let d = this.querySearch.DepartureDate;
        if (!this.isDeparture) d = this.querySearch.ReturnDate;
        let res = [];
        let st = moment(d).add(-4, 'days')
        for (let i=0; i<7; i++) {
            res.push(st.add(1, 'days').format('YYYY-MM-DD'));
        }
        return res;
      },
      getMonth: function(d){
        return moment(d).locale('es').format('MMM');
      },
      getDay: function(d){
        return moment(d).locale('es').format('ddd DD');
      },
      isActive: function(d) {

        var clazz = ''
        let index = this.getWeekDaysDeparture().indexOf(d);
        if (index == 0 || index == 6) {
          clazz = 'd-none d-md-none d-md-inline-block ';
        }
        if (this.isDeparture && d==this.querySearch.DepartureDate) return clazz + 'active-fare-tab';
        if (!this.isDeparture && d==this.querySearch.ReturnDate) return clazz + 'active-fare-tab';
        return clazz;
      },
      getWeekFare: function(d) {
        if (this.isDeparture) {
            if (this.weekFares.departure_flight[d]) {
                return this.weekFares.departure_flight[d].Currency + ' ' + formatMoney(this.weekFares.departure_flight[d].Amount);
            }
        } else {
            if (this.weekFares.return_flight[d]) {
                return this.weekFares.return_flight[d].Currency + ' ' + formatMoney(this.weekFares.return_flight[d].Amount);
            }
        }
        return 'ARS 0,00';

      },
      escalasTxt: function(f) {
        //console.log(f.FlightNumber, f.LegIDs, f);
        let escalas = f.LegIDs.split(",").length - 1;
        if (escalas == -1 || escalas == 0) {
          return "Sin escalas";
        }

        if (escalas == 1) {
          return escalas + " escala";
        }

        return escalas + "escalas"
      },
      setSearchDate: function(d, type) {

          if (this.isDeparture) {
              this.querySearch.DepartureDate = d;
          } else if (this.querySearch.ReturnDate) {
              this.querySearch.ReturnDate = d;
          }
          this.$store.commit('querySearch', this.querySearch);
          this.$store.commit('loading', true);

          getAvailability(this.querySearch, (result)=>{
            this.$store.dispatch('updateFlights', result);
            let periodParams = {
                ADT: this.querySearch.ADT,
                CHD: this.querySearch.CHD,
                DepartureDateStart: moment(this.querySearch.DepartureDate).add(-4, 'days').format('YYYY-MM-DD'),
                DepartureDateEnd: moment(this.querySearch.DepartureDate).add(3, 'days').format('YYYY-MM-DD'),
                DepartureStation: this.querySearch.DepartureStation,
                ArrivalStation: this.querySearch.ArrivalStation
            }
            if (this.querySearch.ReturnDate) {
                periodParams['ReturnDateStart'] = moment(this.querySearch.ReturnDate).add(-4, 'days').format('YYYY-MM-DD');
                periodParams['ReturnDateEnd'] = moment(this.querySearch.ReturnDate).add(3, 'days').format('YYYY-MM-DD');
            }
            getAvailabilityByWeek(periodParams, (result)=>{
                this.$store.dispatch('updateWeekFlights', result);
                this.$store.commit('loading', false);
            });
        });
      }
    },
    computed: {
      ...mapState(['departureFare', 'returnFare', 'querySearch', 'weekFares']),
      icon: function () {
        if (this.isDeparture) {
          return "plane-departure";
        } else {
          return "plane-arrival";
        }
      }
    }
  }
</script>

<style>



</style>