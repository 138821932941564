<template>
  <div class="card shadow">
    <div class="card-header">Mi Reserva</div>
    <div class="card-body">
      <form ref="form">
        <div class="form-row align-items-end">
          <div class="form-group col">
            <label>Apellido</label>
            <input type="text" v-model="lastName" :class="errors.lastName?'form-control form-error': 'form-control'"/>
          </div>
          <div class="form-group col">
            <label>Código de Reserva</label>
            <input type="text" v-model="recordLocator" :class="errors.recordLocator?'form-control form-error': 'form-control'"/>
          </div>
          <div class="form-group col">
            <button type="button" class="btn btn-andes w-100" @click="search">BUSCAR</button>
          </div>
        </div>
        <div class="form-group" v-show="errors.message">
          <p class="is-error">{{errors.message}}</p>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
  import { getBooking } from '@/api/api';
  import { mapState } from 'vuex';
  export default {
    name: 'booking-search',
    computed: {
      ...mapState(['bookingSearch']),
    },
    data: function () {
      return {
        lastName: '',
        recordLocator: '',
        errors: {
          lastName: false,
          recordLocator: false,
          message: ""
        }
      }
    },
    methods: {
      validate: function() {
        this.errors.message = ""
        this.errors.lastName = this.lastName.length == 0;
        this.errors.recordLocator = this.recordLocator.length == 0;
        var isValid = true;
        isValid = isValid && !this.errors.lastName;
        isValid = isValid && !this.errors.recordLocator;

        if (!isValid) {
          this.errors.message = "Complete ambos campos por favor."
        }
        return isValid;
      },

      search: function () {
        if (this.validate()) {
          var queryParams = {
            rl: this.recordLocator,
            ln: this.lastName,
          }
          this.$store.commit('loading', true);
          getBooking(queryParams, (result) => {
            if (result.error) {
              this.errors.lastName = true;
              this.errors.recordLocator = true;
              this.errors.message = result.error;
            } else {
              this.$store.dispatch('updateBooking', result);
              this.$router.push('itinerary');
            }
            this.$store.commit('loading', false);
          });
        }
      }
    },
    mounted() {
      if (!_.isEmpty(this.bookingSearch)) {
        this.errors.lastName = true;
        this.errors.recordLocator = true;
        this.errors.message = this.bookingSearch.error;
        this.recordLocator = this.bookingSearch.recordLocator;
        this.lastName = this.bookingSearch.lastName;
        this.$store.dispatch("bookingSearch", {});
      }
    }
  }
</script>

