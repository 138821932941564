<template>

    <div class="itin-passengers">
      <div class="itin-subheader">
        <h4><font-awesome-icon icon="user"/> Pasajeros</h4>
      </div>


      <div class="itin-passengers-content itin-flex group" v-for="(adult, index) in adults">

          <div class="itin-passenger-content-index">
              <h5>{{index + 1}}</h5>
          </div>
          <div class="text-center text-sm-left w-sm-75"><h5>
            <strong>{{adult.FirstName}} {{adult.MiddleName}} {{adult.LastName}}</strong> (Adulto)
          </h5></div>
          <div class="text-center text-sm-left w-sm-100"><h5>
            | <strong>Nacimiento:</strong> {{adult.DOB}}
          </h5></div>
          <div class="text-center text-sm-left w-sm-100"><h5>
            | <strong>Documento:</strong> {{adult.DocNumber}}
          </h5></div>
          <!--div class="text-center text-sm-left w-sm-100">
              <button class="btn btn-andes" @click="openBoarding(adult.PassengerID)" v-if="adult.paxChecked" >Descargar Tarjeta</button>
          </div-->

      </div>


        <div class="itin-passengers-content itin-flex group" v-for="(child, index) in children">
          <div class="itin-passenger-content-index">
              <h5>{{index + childStartIndex + 1}}</h5>
          </div>
          <div class="text-center text-sm-left w-sm-75"><h5>
            <strong>{{child.FirstName}} {{child.MiddleName}} {{child.LastName}}</strong> (Menor)
          </h5></div>
          <div class="text-center text-sm-left w-sm-100"><h5>
            | <strong>Nacimiento:</strong> {{child.DOB}}
          </h5></div>
          <div class="text-center text-sm-left w-sm-100"><h5>
            | <strong>Documento:</strong> {{child.DocNumber}}
          </h5></div>
        </div>

        <div class="itin-passengers-content itin-flex group" v-for="(inf, index) in infants">
          <div class="itin-passenger-content-index">
              <h5>{{index + infStartIndex + 1}}</h5>
          </div>
          <div class="text-center text-sm-left w-sm-75"><h5>
            <strong>{{inf.FirstName}} {{inf.MiddleName}} {{inf.LastName}}</strong> (Infante)
          </h5></div>
          <div class="text-center text-sm-left w-sm-100"><h5>
            | <strong>Nacimiento:</strong> {{inf.DOB}}
          </h5></div>
          <div class="text-center text-sm-left w-sm-100"><h5>
            | <strong>Documento:</strong> {{inf.DocNumber}}
          </h5></div>
        </div>

    </div>

</template>

<script>
  import { mapState } from 'vuex';
  import { normalizeText  } from '@/utils/tools';
  export default {
    name: 'passenger-summary',
    props: ['flight'],
    computed: {
      ...mapState(['booking', 'lastName', 'dni']),
      childStartIndex: function () {
        return this.adults.length
      },
      infStartIndex: function () {
        return this.adults.length + this.children.length
      },
    },
    data: function () {
      return {
        adults: [],
        children: [],
        infants: [],
      }
    },
    mounted() {
      if (this.booking) {
        this.adults = []
        this.children = []
        this.infants = []

        for (var passenger of this.booking.Passengers) {
          if (this.lastName && this.tlc(normalizeText(this.lastName)) != this.tlc(normalizeText(passenger.LastName))) continue;
          if (this.dni && this.tlc(this.dni) != this.tlc(passenger.DocNumber)) continue;
          var p = {
            FirstName: passenger.FirstName, MiddleName: passenger.MiddleName, LastName: passenger.LastName,
            DOB: passenger.DOB, Gender: passenger.Gender, PaxType: passenger.PaxType,
            DocTypeCode: passenger.DocTypeCode, DocNumber: passenger.DocNumber, Nationality: passenger.Nationality,
            PassportDueDate: "",
            PassportCountry: "",
            PassengerID: passenger.PassengerID,
          }

          let segment = _.find(passenger.Segments, (c) => c.DepartureStation == this.flight.DepartureStation &&
            c.ArrivalStation == this.flight.ArrivalStation && c.DepartureDate == this.flight.DepartureDate);
          if (segment && segment.Legs[0].LiftStatus == 1) {
              p.paxChecked = true;

          };



          if (passenger.PaxType == 'ADT' || passenger.PaxType == 'NREV' || passenger.PaxType == 'CBUS') {
            this.adults.push(p);
            if (this.lastName) {
                this.$store.commit('setPassengerID', passenger.PassengerID)
            }
          } else {
            this.children.push(p);
          }

          for (var infant of passenger.Infants) {
            this.infants.push({
              FirstName: infant.FirstName, MiddleName: infant.MiddleName, LastName: infant.LastName,
              DOB: infant.DOB, Gender: infant.Gender, PaxType: "INF", Nationality: infant.Nationality,
              DocTypeCode: infant.DocTypeCode, DocNumber: infant.DocNumber
            })
          }
        }
      }
    },
    methods: {
      openBoarding: function (paxId) {
        if (paxId) {
            this.$store.commit('setPassengerID', paxId)
        }
        this.$store.commit('boardingFlight', this.flight);
        this.$router.replace("boarding");
      },
      tlc (t) {
          return t.replace(/ /g, '').toLowerCase();
      }
    }
  }
</script>

