<template>
    <div>
        <h5>Información del asiento</h5>
        <div class="card shadow">
            <div class="card-body align-items-center text-center">
                <p class="mb-0" v-if="!information">Mueva el mouse sobre el asiento para ver mas información.</p>
                <p class="mb-0" v-else>{{information}}</p>

            </div>
        </div>
    </div>
</template>

<script>
  export default {
    name: "SeatOverlayInformation",
    props: ['information'],
  }

</script>
