<template>
  <div class="card shadow">
    <div class="card-header">Buscar Vuelos</div>
    <div class="card-body">
      <form ref="form">
        <!-- name -->
        <div class="form-row">
          <div class="form-group col-md-6">
            <input type="checkbox" id="checkbox" v-model="hasReturn">
            <label for="checkbox">Ida y vuelta</label>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-md-6">
            <label>De:</label>
            <select class="form-control" v-model="departureStation"
              :class="errors.departureStation?'form-control form-error': 'form-control'">
              <option :value="airport.id" v-for="airport in airports" :key="airport.id">
                {{airport.name}} ({{airport.id}}) - {{airport.country}}
              </option>
            </select>
          </div>

          <div class="form-group col-md-6">
            <label>A:</label>
            <select class="form-control" v-model="arrivalStation"
              :class="errors.arrivalStation?'form-control form-error': 'form-control'">
              <option :value="airport.id" v-for="airport in airports" :key="airport.id">
                {{airport.name}} ({{airport.id}}) - {{airport.country}}
              </option>
            </select>
          </div>
        </div>

        <div class="form-row">
          <div class="form-group col">
            <!--<date-selector :date.sync="departureDate" label="Salida" ref="departureDate"-->
              <!--:min-year="currentYear" :max-year="currentYear + 1"></date-selector>-->
            <label>Salida</label>
            <input type="date" v-model="departureDate" :class="errors.departureDate?'form-control form-error': 'form-control'"/>
          </div>

          <div class="form-group col" v-show="hasReturn">
            <label>Vuelta</label>
            <input type="date" v-model="returnDate" :class="errors.returnDate?'form-control form-error': 'form-control'"/>
            <!--<date-selector :date.sync="returnDate" label="Vuelta" ref="returnDate"-->
              <!--:min-year="currentYear" :max-year="currentYear + 1"></date-selector>-->
          </div>
        </div>

        <div class="form-row align-items-end">
          <div class="form-group col">
            <label>Adultos</label>
            <input type="number" v-model="adults" :class="errors.adults?'form-control form-error': 'form-control'"/>
          </div>
          <div class="form-group col">
            <label>Menores</label>
            <input type="number" v-model="child" :class="errors.child?'form-control form-error': 'form-control'"/>
          </div>
          <div class="form-group col">
            <label>Infantes</label>
            <input type="number" id="mail" v-model="infants" :class="errors.infants?'form-control form-error': 'form-control'"/>
          </div>
          <div class="form-group col">
            <button type="button" class="btn btn-andes w-100" @click="search">BUSCAR</button>
          </div>
        </div>
      </form>
    </div>
  </div>

</template>

<script>
  import moment from 'moment';
  import { getAvailability, getAvailabilityByWeek } from '@/api/api';
  import { mapState } from 'vuex';
  import DateSelector from "../DateSelector";
  export default {
    components: {DateSelector},
    name: 'flight-search',
    data: function () {
      return {
        departureStation: '',
        arrivalStation: '',
        departureDate: moment().format("YYYY-MM-DD"),
        returnDate: moment().format("YYYY-MM-DD"),
        hasReturn: true,
        adults:1,
        child:0,
        infants:0,
        errors: {
          departureStation: false,
          arrivalStation: false,
          departureDate: false,
          returnDate: false,
          adults:false,
          infants:false
        }
      }
    },
    computed: {
      ...mapState(['querySearch', 'airports']),
      currentYear: function () {
        return moment().year();
      } 
    },
    methods: {
      validate: function() {
        this.errors.departureStation = this.departureStation.length == 0;
        this.errors.arrivalStation = this.arrivalStation.length == 0;
        this.errors.departureDate = this.departureDate.length == 0;
        this.errors.returnDate = this.hasReturn && this.returnDate.length == 0;
        this.errors.adults = this.adults <= 0;
        this.errors.child = this.child < 0;
        this.errors.infants = this.infants > this.adults || this.infants < 0;

        if (this.departureStation == this.arrivalStation) {
          this.errors.departureStation = true;
          this.errors.arrivalStation = true;
        }

        let today = moment().set({hour:0,minute:0,second:0,millisecond:0})
        let dDate = moment(this.departureDate, 'YYYY-MM-DD');
        let rDate = moment(this.returnDate, 'YYYY-MM-DD');
        // before now
        if (today.isAfter(dDate)) {
          this.errors.departureDate = true;
        }

        // before now
        if (today.isAfter(rDate)) {
          this.errors.returnDate = true;
        }

        // return before departure
        if (dDate.isAfter(rDate)) {
          this.errors.departureDate = true;
          this.errors.returnDate = true;
        }

        if (parseInt(this.adults) + parseInt(this.infants) + parseInt(this.child) > 9) {
          this.errors.adults = true;
          this.errors.child = true;
          this.errors.infants = true;
        }

        var isValid = true;
        isValid = isValid && !this.errors.departureStation;
        isValid = isValid && !this.errors.arrivalStation;
        isValid = isValid && !this.errors.departureDate;
        isValid = isValid && !this.errors.returnDate;
        isValid = isValid && !this.errors.adults;
        isValid = isValid && !this.errors.child;
        isValid = isValid && !this.errors.infants;

        return isValid;
      },

      search: function () {
        if (this.validate()) {
          var queryParams = {
            DepartureStation: this.departureStation,
            DepartureDate: this.departureDate,
            ArrivalStation: this.arrivalStation,
            ADT: this.adults,
            CHD: this.child,
            INF: this.infants
          }

          if (this.hasReturn) {
            queryParams['ReturnDate'] = this.returnDate;
          }

          this.$store.commit('querySearch', queryParams);
          this.$store.commit('loading', true);

          getAvailability(queryParams, (result)=>{
            this.$store.commit('adultCount', this.adults);
            this.$store.commit('childCount', this.child);
            this.$store.commit('infantCount', this.infants);
            this.$store.dispatch('updateFlights', result);
            let periodParams = {
                ADT: queryParams.ADT,
                CHD: queryParams.CHD,
                DepartureDateStart: moment(queryParams.DepartureDate).add(-4, 'days').format('YYYY-MM-DD'),
                DepartureDateEnd: moment(queryParams.DepartureDate).add(3, 'days').format('YYYY-MM-DD'),
                DepartureStation: queryParams.DepartureStation,
                ArrivalStation: queryParams.ArrivalStation
            }
            if (this.hasReturn) {
                periodParams['ReturnDateStart'] = moment(queryParams.ReturnDate).add(-4, 'days').format('YYYY-MM-DD');
                periodParams['ReturnDateEnd'] = moment(queryParams.ReturnDate).add(3, 'days').format('YYYY-MM-DD');
            }
            getAvailabilityByWeek(periodParams, (result)=>{
                this.$store.dispatch('updateWeekFlights', result);
                this.$router.push('flight');
                this.$store.commit('loading', false);
            });

          });
        }
      }
    },
    mounted() {
      this.departureDate = this.querySearch.DepartureDate != undefined ? this.querySearch.DepartureDate: "";
      this.departureStation = this.querySearch.DepartureStation != undefined? this.querySearch.DepartureStation: "";
      this.returnDate = this.querySearch.ReturnDate != undefined? this.querySearch.ReturnDate: "" ;
      this.arrivalStation = this.querySearch.ArrivalStation != undefined? this.querySearch.ArrivalStation: "";
      this.adults = this.querySearch.ADT != undefined? this.querySearch.ADT : 1;
      this.child = this.querySearch.CHD != undefined? this.querySearch.CHD : 0;
      this.infants = this.querySearch.INF != undefined? this.querySearch.INF : 0;
    }
  }
</script>

