<template>
    <footer class="mt-3 pb-3">
      <div class="container">
        <a href="http://qr.afip.gob.ar/?qr=ulNnf5MDh2X0ld4nwcovdw,," target="_F960AFIPInfo" class="logo_afip"><img src="@/img/dataweb.jpg" width="45" height="60" border="0"></a>
        <p>Andes Líneas Aéreas S.A. Todos los derechos Reservados. </p>
        <div class="social_icons">
        </div>
      </div>
    </footer>

</template>
`
<script>
  export default {
    name: 'andes-footer',
  }
</script>

