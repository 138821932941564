<template>
    <div class="container">
        <nav class="row breadcrumb-row">
          <div class="btn-group btn-breadcrumb">
            <a class="btn" :class="{'btn-active':activePage>=0, 'btn-default':activePage != 0}" @click="navigateTo('flight', 0)"><span>1</span> Elegir el vuelo</a>
            <a class="btn" :class="{'btn-active':activePage>=1, 'btn-default':activePage != 1}" @click="navigateTo('passengers', 1)"><span>2</span> Datos del pasajero</a>
            <a class="btn" :class="{'btn-active':activePage>=2, 'btn-default':activePage != 2}" @click="navigateTo('extras', 2)"><span>3</span> Extras</a>
            <a class="btn" :class="{'btn-active':activePage>=3, 'btn-default':activePage != 3}" @click="navigateTo('payment', 3)"><span>4</span> Realizar pago</a>
            <a class="btn" :class="{'btn-active':activePage>=4, 'btn-default':activePage != 4}" @click="navigateTo('itinerary', 4)"><span>5</span> Itinerario</a>
          </div>
        </nav>
    </div>
</template>

<script>
  import { mapState } from 'vuex';

  export default {
    name: 'breadcrumb',
    props:["activePage"],
    computed: {
      ...mapState(['booking']),
    },
    methods:{
      navigateTo: function (page, pageNumber) { 

//        if (this.booking.BalanceDue <=0) {
//          this.goToItinerary();
//          return;
//        }

//        if (pageNumber < this.activePage) {
          this.$router.go(pageNumber - this.activePage);
//        }

      },
      goToItinerary: function () {
        this.$router.history = [];
        this.$router.replace("itinerary");
      }
    },
    mounted() {
//      console.log(this.activePage, this.booking.Passengers);
//
//      if (this.activePage > 1 && this.booking.RecordLocator == undefined) {
//        this.$router.replace('search');
//      }

    }
  }
</script>

