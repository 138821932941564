<template>
  <div>
    <andes-header></andes-header>
    <div class="container mt-3">
      <div class="row">
        <div class="col px-0">
          <flight-search></flight-search>
        </div>
      </div>
      <div class="row">
        <div class="col px-0 py-4">
          <booking-search></booking-search>
        </div>
      </div>

    </div>
    <loading-modal v-show="loading"></loading-modal>
    <andes-footer></andes-footer>
  </div>
</template>

<script>
  import moment from 'moment';
  import { mapState } from 'vuex';
  import { getAvailability, getAvailabilityByWeek  } from '@/api/api';
  import LoadingModal from "../components/LoadingModal";
  import AndesHeader from "../components/AndesHeader";
  import FlightSearch from "../components/search/FlightSearch";
  import BookingSearch from "../components/search/BookingSearch";
  import AndesFooter from "../components/AndesFooter";
  export default {
    components: {
      FlightSearch,
      AndesHeader,
      BookingSearch,
      LoadingModal,
      AndesFooter,
      },
    name: 'automatic-search',
    computed: {
      ...mapState(['querySearch', 'loading']),
    },

    data: function () {
      return {
        query: {}
      }
    },

    mounted: function () {
      this.resolvePath();
      this.query = {
        DepartureStation: this.$route.query.o1,
        ArrivalStation: this.$route.query.d1,
        DepartureDate: this.$route.query.dd1,
        ADT: this.$route.query.ADT,
        CHD: this.$route.query.CHD,
        INF: this.$route.query.inl
      };

      // si es ida y vuelta tomo dd2
      if (this.$route.query.r != undefined && this.$route.query.r == "true") {
        this.query.ReturnDate = this.$route.query.dd2;
      }

      this.$store.commit('querySearch', this.query);
      if (this.query.DepartureDate != undefined && this.query.DepartureStation != undefined
        && this.query.ArrivalStation != undefined && this.query.ADT != undefined) {
        this.$store.commit('loading', true);


        getAvailability(this.query, (result)=>{
          this.$store.commit('adultCount', this.query.ADT);
          if (this.query.CHD) {
            this.$store.commit('childCount', this.query.CHD);
          }
          if (this.query.INF) {
            this.$store.commit('infantCount', this.query.INF);
          }
          this.$store.dispatch('updateFlights', result);

            let periodParams = {
                ADT: this.query.ADT,
                CHD: this.query.CHD? this.query.CHD: 0,
                DepartureDateStart: moment(this.query.DepartureDate).add(-4, 'days').format('YYYY-MM-DD'),
                DepartureDateEnd: moment(this.query.DepartureDate).add(3, 'days').format('YYYY-MM-DD'),
                DepartureStation: this.query.DepartureStation,
                ArrivalStation: this.query.ArrivalStation
            }
            if (!_.isEmpty(this.query.ReturnDate)) {
                periodParams['ReturnDateStart'] = moment(this.query.ReturnDate).add(-4, 'days').format('YYYY-MM-DD');
                periodParams['ReturnDateEnd'] = moment(this.query.ReturnDate).add(3, 'days').format('YYYY-MM-DD');
            }
            getAvailabilityByWeek(periodParams, (result)=>{
                this.$store.dispatch('updateWeekFlights', result);
                this.$router.push('flight');
                this.$store.commit('loading', false);

            });

          //this.$router.push('flight');
          //this.$store.commit('loading', false);
        });
      }

    },
    methods: {
        resolvePath: function() {
            let loc = window.location;
            if (loc.pathname=='/FlightSearch') {
                let search = loc.search;
                let newpath = origin + '/#/search' + search;
                window.location.href = newpath;
            }
            if (loc.pathname=='/BookingSearch') {
                let search = loc.search;
                let newpath = origin + '/#/BookingSearch' + search;
                window.location.href = newpath;
            }

        }
    }
  }
</script>

