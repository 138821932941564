<template>
  <div>
    <andes-header></andes-header>

    <div class="container">
      <div class="row">
        <div class="col">
          <h5>Vuelo OY {{checkinFlight.Legs[0].InventoryLeg.FlightNumber}} <strong>{{checkinFlight.DepartureStation}}
            <img src="@/img/plane-32.png" alt="Flying to">
            {{checkinFlight.ArrivalStation}}</strong> {{getFormatDate}}</h5>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-6">
          <seatmap-plane :seat-map="seatMapDictionary" @assignSeat="assignSeat"
                         :current-pax="currentPax"></seatmap-plane>
        </div>
        <div class="col-12 col-md-6 seat-info-box">
          <seat-overlay-information :information="information"></seat-overlay-information>
          <seat-select-passenger class="mt-3" :paxs="paxs" :current-pax="currentPax"
                                 @select-pax="onSelectPax"></seat-select-passenger>
          <hr>
          <seatmap-references/>
          <div class="row mt-3">
            <div class="col-6">
              <button type="button" class="btn btn-link" @click="onCancelChanges">Cancelar Cambios</button>
            </div>
            <div class="col-6 text-right">
              <button type="button" class="btn btn-continue" @click="onContinue">Continuar</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <andes-footer></andes-footer>
    <loading-modal v-if="loading"></loading-modal>
  </div>
</template>

<script>
  import moment from 'moment';
  import {getSeatMap, postAssignSeat, removeAssignSeat} from '@/api/api';
  import {mapState} from 'vuex';
  import AndesHeader from "../components/AndesHeader";
  import AndesFooter from "../components/AndesFooter";
  import LoadingModal from "../components/LoadingModal";
  import SeatOverlayInformation from "../components/seatmap/SeatOverlayInformation";
  import SeatSelectPassenger from "../components/seatmap/SeatSelectPassenger";
  import SeatmapPlane from "../components/seatmap/Seatmap";
  import SeatmapReferences from "../components/seatmap/SeatmapReferences";

  export default {
    name: "SeatMapPage",
    components: {
      SeatmapReferences,
      SeatmapPlane,
      SeatSelectPassenger, SeatOverlayInformation, LoadingModal, AndesFooter, AndesHeader
    },
    computed: {
      ...mapState(['booking', 'loading', 'checkinFlight', 'segmentsToCheck']),
      getFormatDate: function () {
        return moment(this.checkinFlight.DepartureDate).locale('es').format('DD MMMM YYYY')
      },
      paxs: function () {
        var list = [];
        for (var segmentId of this.segmentsToCheck) {
          for (var pax of this.booking.Passengers) {
            for (var seg of pax.Segments) {
              if (seg.SegmentID == segmentId) {
                list.push({'pax': pax, 'segId': segmentId, 'paxLeg': seg.Legs[0]});
              }
            }
          }
        }
        this.currentPax = list[0]
        // this.paxs = list;
        return list
      }
    },
    data: function () {
      return {
        information: "",
        seatMapDictionary: {},
        currentPax: null,
        balanceType: null
      }
    },
    methods: {
      getOriginalSeat: function (paxToChange) {
        for (var pax of this.booking.Passengers) {
          for (var seg of pax.Segments) {
            if (seg.SegmentID == paxToChange.segId) {
              return seg.Legs[0].UnitDesignator
            }
          }
        }
      },
      assignSeat: function (pax, seat) {
        let params = {
          'RecordLocator': this.booking.RecordLocator,
          'UnitDesignator': seat,
          'LegNumber': pax.paxLeg.LegNumber,
          'SegmentID': pax.segId,
          'FlightId': pax.paxLeg.InventoryLeg.FlightId
        }
        this.$store.commit('loading', true);
        postAssignSeat(params, (result) => {
          this.seatMapDictionary = result.SeatMap[this.checkinFlight.Legs[0].InventoryLegID];
          this.currentPax.paxLeg = result.PassengerLeg;
          this.nextPassenger();
          this.$store.commit('loading', false);
          /*gtag('event', 'assign_seat', {
            'event_category': "seatmap",
          });*/
        })
      },
      nextPassenger: function () {
        let index = this.paxs.indexOf(this.currentPax);

        if (this.paxs.length - 1 > index) {
          this.currentPax = this.paxs[index + 1];
        } else {
          // goTo confirmationPage
          // console.log("confirmationPage");
        }
      },
      onCancelChanges: function () {
        for (var pax of this.paxs) {

          var currentSeat = pax.paxLeg.UnitDesignator;
          var originalSeat = this.getOriginalSeat(pax);
          if (currentSeat != originalSeat) {
            if (originalSeat != null) {
              this.assignSeat(pax, originalSeat);
            } else {
              // removeSeat
              this.removeSeat(pax);
            }
          }
        }
        this.$router.replace('itinerary');
      },
      removeSeat: function (pax) {
        let params = {
          'RecordLocator': this.booking.RecordLocator,
          'LegNumber': pax.paxLeg.LegNumber,
          'FlightId': pax.paxLeg.InventoryLeg.FlightId,
          'SegmentID': pax.segId
        }
        removeAssignSeat(params, (result) => {
          this.seatMapDictionary = result.SeatMap[this.checkinFlight.Legs[0].InventoryLegID];
          this.currentPax.paxLeg = result.PassengerLeg;
          /*gtag('event', 'remove_seat', {
            'event_category': "seatmap"
          });*/
        })
      },
      onContinue: function () {

        let index = this.paxs.indexOf(this.currentPax);

        if (this.paxs.length - 1 > index) {
          this.currentPax = this.paxs[index + 1];
        } else {
          this.$store.commit('updateSeats', this.paxs);
          // goTo confirmationPage
          this.$router.replace("checkinreview");
        }
      },
      onSelectPax: function (value) {
        this.currentPax = value;
      },
      balanceSeatmap: function () {
        if (this.balanceType == null || this.balanceType == 'none') {
          return;
        }

        var min = 1;
        var max = 13;
        if (this.balanceType == 'block_back') {
          min = 24;
          max = 32;
        }

        for (let row=min; row<max; row++ ) {
          for (let column of ['A', 'B' ,'C', 'D', 'E', 'F']) {
            let unitDesignator = row + column;
            if (this.seatMapDictionary.hasOwnProperty(unitDesignator)) {
              this.seatMapDictionary[unitDesignator].Status = 'UNAVAILABLE';
            }
          }
        }
      }
    },
    mounted() {
      if (_.isEmpty(this.booking)) {
        this.$router.replace('/');
      }

      this.$store.commit('loading', true);
      let legIDs = [];
      for (let leg of this.checkinFlight.Legs) {
        legIDs.push(leg.InventoryLegID)
      }
      getSeatMap(legIDs, (seatMapDictionary) => {
        this.seatMapDictionary = seatMapDictionary;
        this.$store.commit('loading', false);
      });
    },
    watch: {
      seatMapDictionary: function () {
        if (this.balanceType != null) {
          this.balanceSeatmap();
          return;
        }
        let frontPassengers = 0;
        let backPassengers = 0;
        for (var row=1; row<32; row++ ) {
          for (var column of ['A','B', 'C','D', 'E', 'F']) {
            let unitDesignator = row + column;
            if (this.seatMapDictionary.hasOwnProperty(unitDesignator)) {
              let seat = this.seatMapDictionary[unitDesignator];
              if (seat.Status !== 'AVAILABLE' ) {
                if (row <= 12) {
                  frontPassengers += 1;
                }
                if (row >= 24) {
                  backPassengers += 1;
                }
              }
            }
          }
        }

        if (frontPassengers - backPassengers >= 6) {
          this.balanceType = 'block_front';
        } else if (backPassengers - frontPassengers >= 6) {
          this.balanceType = 'block_back';
        } else {
          this.balanceType = 'none';
        }

        //console.log(this.balanceType, this.checkinFlight.Legs[0].InventoryLegID, frontPassengers, backPassengers);

        this.balanceSeatmap();
      }
    }
  }
</script>

