<template>
  <div>
    <h5>Referencias</h5>
    <div class="card shadow seat-reference">
      <table>

        <tr>
          <td>
            <ol>
              <li class="seat">
                <input type="checkbox">
                <label>-</label>
              </li>
            </ol>
          </td>
          <td class="seat-reference-text">Disponible</td>
          <td>
            <ol>
              <li class="seat">
                <input type="checkbox" disabled>
                <label>-</label>
              </li>
            </ol>
          </td>
          <td class="seat-reference-text">No Disponible</td>
        </tr>

        <tr>
          <td>
            <ol>
              <li class="seat">
                <input type="checkbox" checked="">
                <label>-</label>
              </li>
            </ol>
          </td>
          <td class="seat-reference-text">Seleccionado</td>
          <td>
            <ol>
              <li class="seat">
                <input type="checkbox" disabled>
                <label>-</label>
              </li>
            </ol>
          </td>
          <td class="seat-reference-text">Otro Pasajero</td>
        </tr>

        <tr>
          <td>
            <ol>
              <li class="exit-li">
                <span>Exit</span>
              </li>
            </ol>
          </td>
          <td class="seat-reference-text">Salida</td>
          <td>
            <ol>
              <li class="seat">
                <span class="bthr1"><i class="fas fa-restroom"></i></span>
              </li>
            </ol>
          </td>
          <td class="seat-reference-text">Toilletes</td>
        </tr>

      </table>
    </div>
  </div>

</template>

<script>
  export default {
    name: "SeatmapReferences"
  }
</script>

<style scoped>

</style>